import React from 'react';

import { imageCaffe, imageStores, imageVips, imageWedding } from '../data/slider';

const ServiceCart = () => {
  return (
    <div className="container" id="imageLib">
      <div className="d-flex flex-row justify-content-center align-items-center mt-5 ">
        <div>
          <img src="/img/flower_left.png" alt="flower_icon" />
        </div>
        <div
          className="upercase "
          style={{ textTransform: 'uppercase', margin: '0 10px 0 10px', fontSize: '32px' }}
        >
          Thư viện ảnh
        </div>

        <div>
          <img style={{ float: 'right' }} src="/img/flower_right.png" alt="flower_icon" />
        </div>
      </div>

      <div class="container-fluid">
        <div className="row align-items-center mb-2 mt-5">
          <div
            className="nav flex-column nav-pills nav-shadow pb-5 col-md-3 col-12"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <button
              data-bs-target="#v-pills-banquet"
              type="button"
              role="tab"
              aria-controls="v-pills-banquet"
              aria-selected="true"
              id="v-pills-banquet-tab"
              data-bs-toggle="pill"
              className="d-flex flex-row d-flex justify-content-between align-items-center mt-1 mb-1 nav-link active btn-image-lib"
            >
              <div>
                <img src="/img/image_lib_left.png" alt="flower_icon" />
              </div>
              <div
                className="image-lib-title"
                style={{
                  textTransform: 'uppercase'
                }}
              >
                Tiệc cưới
              </div>

              <div>
                <img src="/img/image_lib_right.png" alt="flower_icon" />
              </div>
            </button>

            <div
              data-bs-target="#v-pills-confurent"
              type="button"
              role="tab"
              aria-controls="v-pills-confurent"
              aria-selected="true"
              id="v-pills-confurent-tab"
              data-bs-toggle="pill"
              className="d-flex flex-row d-flex justify-content-between align-items-center mt-1 mb-1 nav-link btn-image-lib"
            >
              <div>
                <img src="/img/image_lib_left.png" alt="flower_icon" />
              </div>
              <div
                className="image-lib-title"
                style={{
                  textTransform: 'uppercase'
                }}
              >
                Hội nghị - hội thao
              </div>

              <div>
                <img src="/img/image_lib_right.png" alt="flower_icon" />
              </div>
            </div>
            <div
              data-bs-target="#v-pills-vip"
              type="button"
              role="tab"
              aria-controls="v-pills-vip"
              aria-selected="true"
              id="v-pills-vip-tab"
              data-bs-toggle="pill"
              className="d-flex flex-row d-flex justify-content-between align-items-center mt-1 mb-1 nav-link btn-image-lib"
            >
              <div>
                <img src="/img/image_lib_left.png" alt="flower_icon" />
              </div>
              <div
                className="image-lib-title"
                style={{
                  textTransform: 'uppercase'
                }}
              >
                Phòng vip
              </div>

              <div>
                <img src="/img/image_lib_right.png" alt="flower_icon" />
              </div>
            </div>
            <div
              data-bs-target="#v-pills-restaurent"
              type="button"
              role="tab"
              aria-controls="v-pills-restaurent"
              aria-selected="true"
              id="v-pills-restaurent-tab"
              data-bs-toggle="pill"
              className="d-flex flex-row d-flex justify-content-between align-items-center mt-1 mb-1 nav-link btn-image-lib"
            >
              <div>
                <img src="/img/image_lib_left.png" alt="flower_icon" />
              </div>
              <div
                className="image-lib-title"
                style={{
                  textTransform: 'uppercase'
                }}
              >
                Nhà hàng
              </div>

              <div>
                <img src="/img/image_lib_right.png" alt="flower_icon" />
              </div>
            </div>
            <button
              data-bs-target="#v-pills-caffe"
              type="button"
              role="tab"
              aria-controls="v-pills-caffe"
              aria-selected="true"
              id="v-pills-caffe-tab"
              data-bs-toggle="pill"
              className="d-flex flex-row d-flex justify-content-between align-items-center mt-1 mb-1 nav-link btn-image-lib"
            >
              <div>
                <img src="/img/image_lib_left.png" alt="flower_icon" />
              </div>
              <div
                className="image-lib-title"
                style={{
                  textTransform: 'uppercase'
                }}
              >
                CAFE
              </div>

              <div>
                <img src="/img/image_lib_right.png" alt="flower_icon" />
              </div>
            </button>
          </div>
          <div className="tab-content col-md-9 col-12 pb-5" id="v-pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="v-pills-banquet"
              role="tabpanel"
              aria-labelledby="v-pills-banquet-tab"
            >
              <div id="demo" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators  mt-4">
                  {imageStores.map((item, index) => {
                    return (
                      <button
                        type="button"
                        data-bs-target="#demo"
                        data-bs-slide-to={index}
                        className={`btn-warning ` + (index == 0 ? 'active' : '')}
                      ></button>
                    );
                  })}
                </div>

                <div className="carousel-inner">
                  {imageStores.map((item, index) => {
                    return (
                      <div className={`carousel-item ` + (index == 0 ? 'active' : '')}>
                        <div className="row align-items-center">
                          <div className="col-md-8 col-12 content-image-lib">
                            <img
                              src={'/img/slider/wedding/slider_weding_' + item.index + '.png'}
                              alt="Los Angeles"
                              className="image-lib-slider w-100"
                            />
                          </div>
                          <div className="col-12 col-md-4" style={{ textAlign: 'justify' }}>
                            {item.content}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#demo"
                  data-bs-slide="prev"
                >
                  <span class="carousel-control-prev-icon"></span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#demo"
                  data-bs-slide="next"
                >
                  <span class="carousel-control-next-icon"></span>
                </button>
              </div>
            </div>
            <div
              className="tab-pane fade pt-4  "
              id="v-pills-confurent"
              role="tabpanel"
              aria-labelledby="v-pills-confurent-tab"
            >
              <div id="demo2" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators  pt-4">
                  {imageWedding.map((item, index) => {
                    return (
                      <button
                        type="button"
                        data-bs-target="#demo2"
                        data-bs-slide-to={index}
                        className={`btn-warning ` + (index == 0 ? 'active' : '')}
                      ></button>
                    );
                  })}
                </div>

                <div className="carousel-inner">
                  {imageWedding.map((item, index) => {
                    return (
                      <div className={`carousel-item ` + (index == 0 ? 'active' : '')}>
                        <div className="row align-items-center">
                          <div className="col-md-8 col-12 content-image-lib">
                            <img
                              src={'/img/slider/store/slider_store_' + item.index + '.png'}
                              alt="Los Angeles"
                              className="image-lib-slider w-100"
                            />
                          </div>
                          <div className="col-12 col-md-4" style={{ textAlign: 'justify' }}>
                            {item.content}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#demo2"
                  data-bs-slide="prev"
                >
                  <span class="carousel-control-prev-icon"></span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#demo2"
                  data-bs-slide="next"
                >
                  <span class="carousel-control-next-icon"></span>
                </button>
              </div>
            </div>
            <div
              className="tab-pane fade pt-4  "
              id="v-pills-vip"
              role="tabpanel"
              aria-labelledby="v-pills-vip-tab"
            >
              <div id="demo3" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators  pt-4">
                  {imageVips.map((item, index) => {
                    return (
                      <button
                        type="button"
                        data-bs-target="#demo3"
                        data-bs-slide-to={index}
                        className={`btn-warning ` + (index == 0 ? 'active' : '')}
                      ></button>
                    );
                  })}
                </div>

                <div className="carousel-inner">
                  {imageVips.map((item, index) => {
                    return (
                      <div className={`carousel-item ` + (index == 0 ? 'active' : '')}>
                        <div className="row align-items-center">
                          <div className="col-md-8 col-12 content-image-lib">
                            <img
                              src={'/img/slider/romVip/slider_vip_' + item.index + '.png'}
                              alt="Los Angeles"
                              className="image-lib-slider w-100"
                            />
                          </div>
                          <div className="col-12 col-md-4" style={{ textAlign: 'justify' }}>
                            {item.content}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#demo3"
                  data-bs-slide="prev"
                >
                  <span class="carousel-control-prev-icon"></span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#demo3"
                  data-bs-slide="next"
                >
                  <span class="carousel-control-next-icon"></span>
                </button>
              </div>
            </div>
            <div
              className="tab-pane fade pt-4  "
              id="v-pills-restaurent"
              role="tabpanel"
              aria-labelledby="v-pills-restaurent-tab"
            >
              <div id="demo4" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators  pt-4">
                  {imageStores.map((item, index) => {
                    return (
                      <button
                        type="button"
                        data-bs-target="#demo4"
                        data-bs-slide-to={index}
                        className={`btn-warning ` + (index == 0 ? 'active' : '')}
                      ></button>
                    );
                  })}
                </div>

                <div className="carousel-inner">
                  {imageStores.map((item, index) => {
                    return (
                      <div className={`carousel-item ` + (index == 0 ? 'active' : '')}>
                        <div className="row align-items-center">
                          <div className="col-md-8 col-12 content-image-lib">
                            <img
                              src={'/img/slider/store/slider_store_' + item.index + '.png'}
                              alt="Los Angeles"
                              className="image-lib-slider w-100"
                            />
                          </div>
                          <div className="col-12 col-md-4" style={{ textAlign: 'justify' }}>
                            {item.content}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#demo4"
                  data-bs-slide="prev"
                >
                  <span class="carousel-control-prev-icon"></span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#demo4"
                  data-bs-slide="next"
                >
                  <span class="carousel-control-next-icon"></span>
                </button>
              </div>
            </div>
            <div
              className="tab-pane fade pt-4  "
              id="v-pills-caffe"
              role="tabpanel"
              aria-labelledby="v-pills-caffe-tab"
            >
              <div id="demo5" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators  pt-4">
                  {imageCaffe.map((item, index) => {
                    return (
                      <button
                        type="button"
                        data-bs-target="#demo5"
                        data-bs-slide-to={index}
                        className={`btn-warning ` + (index == 0 ? 'active' : '')}
                      ></button>
                    );
                  })}
                </div>

                <div className="carousel-inner">
                  {imageCaffe.map((item, index) => {
                    return (
                      <div className={`carousel-item ` + (index == 0 ? 'active' : '')}>
                        <div className="row align-items-center">
                          <div className="col-md-8 col-12 -lib">
                            <img
                              src={'/img/slider/cafe/slider_caffe_' + item.index + '.png'}
                              alt="Los Angeles"
                              className="image-lib-slider w-100"
                              style={{ maxHeight: '500px', objectFit: 'contain' }}
                            />
                          </div>
                          <div className="col-12 col-md-4 p-2" style={{ textAlign: 'justify' }}>
                            {item.content}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#demo5"
                  data-bs-slide="prev"
                >
                  <span class="carousel-control-prev-icon"></span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#demo5"
                  data-bs-slide="next"
                >
                  <span class="carousel-control-next-icon"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCart;
