import React from 'react';
import { Carousel, Col, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

const reviews = [
  {
    id: 1,
    name: 'NGUYỄN DUY - ĐẶNG NA, HẢI PHÒNG',
    content:
      '"LỜI CẢM ƠN CHÂN THÀNH ĐẾN NHÀ HÀNG TIỆC CƯỚI PHÚ MỸ HƯNG! SỰ CHUYÊN NGHIỆP VÀ CHU ĐÁO CỦA ĐỘI NGŨ NHÂN VIÊN ĐÃ LÀM CHO NGÀY CƯỚI CỦA CHÚNG TÔI TRỞ THÀNH MỘT KỶ NIỆM ĐẸP ĐẼ VÀ KHÔNG THỂ QUÊN. MỌI THỨ ĐỀU ĐƯỢC TỔ CHỨC HOÀN HẢO, TỪ KHÔNG GIAN ĐẾN THỰC ĐƠN. CHÚNG TÔI RẤT HẠNH PHÚC VÀ HÀI LÒNG VỚI DỊCH VỤ CỦA QUÝ VỊ. XIN CẢM ƠN RẤT NHIỀU!"',
    imageUrl: '/img/comment/image-slide1.png'
  },
  {
    id: 2,
    name: 'MAI ANH - HOÀNG PHÚC, HẢI PHÒNG',
    content:
      '“NHỮNG NGÀY GẦN CƯỚI, HAI VỢ CHỒNG MÌNH BẬN KHÔNG CÒN THỜI GIAN QUAN TÂM GÌ ĐẾN VIỆC SẮP XẾP VÀ DECOR KHÔNG GIAN CƯỚI. NHƯNG CŨNG MAY CÓ ĐỘI WEDDING PLANNER CỦA TRUNG TÂM ĐÃ GIÚP TÔI LO LIỆU TẤT CẢ CÁC KHÂU TỪ NHỮNG CHI TIẾT NHỎ NHẤT. THỰC SỰ CẢM ƠN ĐỘI NGŨ EKIP CỦA BÊN MÌNH NHIỀU LẮM.”',
    imageUrl: '/img/comment/image-slide2.png'
  },
  {
    id: 3,
    name: 'HỒNG QUANG - MINH LÝ, HẢI PHÒNG',
    content:
      '“ANH CHỊ NHẬN ĐƯỢC RẤT NHIỀU LỜI KHEN TỪ BẠN BÈ VÀ KHÁCH MỜI ĐẾN THAM DỰ VÌ KHÔNG GIAN THOÁNG ĐÃNG, MÁT, THỨC ĂN NGON, TRANG TRÍ ĐÈN QUẢ ĐẸP, CÂY XANH NHIỀU, BÀN GHẾ ĐẸP PHỤC VỤ TẬN TÌNH, VÀ NGOÀI SỰ MONG ĐỢI. NÓI CHUNG LÀ QUÁ TUYỆT VỜI!!! KÍNH CHÚC PHÚ MỸ HƯNG LUÔN THÀNH CÔNG TỐT ĐẸP VÀ THẬT NHIỀU SỨC KHỎE, TRAN ĐẦY...”',
    imageUrl: '/img/comment/image-slide3.png'
  },
  {
    id: 4,
    name: 'THẾ ANH - HOÀNG PHƯƠNG, HẢI PHÒNG',
    content:
      '"LỜI CẢM ƠN CHÂN THÀNH ĐẾN NHÀ HÀNG TIỆC CƯỚI PHÚ MỸ HƯNG! SỰ CHUYÊN NGHIỆP VÀ CHU ĐÁO CỦA ĐỘI NGŨ NHÂN VIÊN ĐÃ LÀM CHO NGÀY CƯỚI CỦA CHÚNG TÔI TRỞ THÀNH MỘT KỶ NIỆM ĐẸP ĐẼ VÀ KHÔNG THỂ QUÊN. MỌI THỨ ĐỀU ĐƯỢC TỔ CHỨC HOÀN HẢO, TỪ KHÔNG GIAN ĐẾN THỰC ĐƠN. CHÚNG TÔI RẤT HẠNH PHÚC VÀ HÀI LÒNG VỚI DỊCH VỤ CỦA QUÝ VỊ. XIN CẢM ƠN RẤT NHIỀU!"',
    imageUrl: '/img/comment/image-slide4.png'
  },
  {
    id: 5,
    name: 'MINH ĐĂNG - LAN HƯƠNG, HẢI PHÒNG',
    content:
      '"TÔI ĐÃ GẶP ĐƯỢC VỢ HIỆN TẠI CỦA TÔI TRONG MỘT LẦN DỰ ĐÁM CƯỚI CỦA BẠN THÂN TẠI PHÚ MỸ HƯNG. VÀ 1 NĂM SAU ĐÓ, CŨNG TẠI ĐÂY CHÚNG TÔI ĐÃ TỔ CHỨC LỄ CƯỚI. CẢM ƠN PHÚ MỸ HƯNG VÌ MỐI NHÂN DUYÊN NÀY!"',
    imageUrl: '/img/comment/image-slide5.png'
  },
  {
    id: 6,
    name: 'CHU THỊNH - PHƯƠNG NHI, HẢI PHÒNG',
    content:
      '“MÌNH XIN GỬI LỜI CẢM ƠN CHÂN THÀNH ĐẾN TRUNG TÂM TỔ CHỨC TIỆC CƯỚI PHÚ MỸ HƯNG ĐÃ HỖ TRỢ HẾT SỨC ĐỂ VỢ CHỒNG MÌNH CÓ ĐƯỢC MỘT ĐÁM CƯỚI NHƯ Ý. XIN CẢM ƠN CÁC BẠN QUẢN LÝ TỪ TRUNG TÂM ĐÃ TẬN TÂM CHĂM CHÚT ĐẾN TỪNG CHI TIẾT NHỎ ...”',
    imageUrl: '/img/comment/image-slide6.png'
  }
];

const CustomerReviewSlider = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <div
      className="customer-review-slider-wrapper"
      style={{ backgroundImage: `url('/img/reservation/background-form.png')` }}
    >
      <div
        className="customer-review-header"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '2rem'
        }}
      >
        <img
          src="/img/menu/Vector2.png"
          alt="Left Image"
          style={{ marginRight: '20px', width: '50px', height: '50px' }}
        />
        <h2 style={{ textAlign: 'center', color: 'white', fontWeight: 'bold' }}>
          NHẬN XÉT CỦA KHÁCH HÀNG
        </h2>
        <img
          src="/img/menu/Vector1.png"
          alt="Right Image"
          style={{ marginLeft: '20px', width: '50px', height: '50px' }}
        />
      </div>

      <div className="carousel-container" style={{ padding: '50px' }}>
        <Carousel
          className="customer-review-slider"
          style={{
            padding: isMobile ? '1rem' : '5rem',
            background: 'white',
            maxWidth: '70%',
            margin: 'auto',
            borderRadius: '10px'
          }}
        >
          {reviews.map((review) => (
            <Carousel.Item key={review.id}>
              <Row>
                <Col xs={12} sm={5} md={3} style={{ textAlign: 'center' }}>
                  <img
                    className="d-block w-100"
                    style={{ maxWidth: '80%', height: 'auto', margin: 'auto' }}
                    src={review.imageUrl}
                    alt={`Slide ${review.id}`}
                  />
                </Col>

                <Col
                  xs={12}
                  sm={7}
                  md={9}
                  style={{
                    // display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: isMobile ? 'center' : ''
                  }}
                >
                  <h3
                    style={{
                      fontWeight: 'bold',
                      color: '#8a722d',
                      marginTop: '0',
                      marginBottom: '10px',
                      fontSize: isMobile ? '1rem' : '1.2rem',
                      padding: isMobile ? '0.5rem' : '2rem'
                    }}
                  >
                    {review.name.toUpperCase()}
                  </h3>
                  <Carousel.Caption
                    style={{
                      color: '#000',
                      position: 'static',
                      padding: isMobile ? '0.5rem' : '2rem'
                    }}
                  >
                    <p
                      style={{
                        textAlign: 'left',
                        margin: '0',
                        fontSize: isMobile ? '0.7rem' : '1rem',
                        fontStyle: 'italic',
                        paddingBottom: isMobile ? '1rem' : '0rem'
                      }}
                    >
                      {review.content}
                    </p>
                  </Carousel.Caption>
                </Col>
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default CustomerReviewSlider;
