import React from 'react';
import { Link } from 'react-router-dom';

const ServiceCart = () => {
  return (
    <div className="container">
      <div className="d-flex flex-row justify-content-center align-items-center mt-5 ">
        <div>
          <img src="/img/flower_left.png" alt="flower_icon" />
        </div>
        <div
          className="upercase title-hone-intro-common"
          style={{ textTransform: 'uppercase', margin: '0 10px 0 10px', fontSize: '32px' }}
        >
          Dịch vụ phục vụ
        </div>

        <div>
          <img src="/img/flower_right.png" alt="flower_icon" />
        </div>
      </div>
      <div>
        <div className="row">
          <div className="col-lg-6 col-12">
            <div className="d-flex flex-column">
              <div className="box-service">
                <div className="service-title">TIỆC CƯỚI - HÔI NGHỊ</div>
                <div className="service-content" style={{ textAlign: 'justify' }}>
                  Hôn nhân là sự kiện trọng đại của cuộc đời, ai cũng mong muốn ghi lại từng giây
                  phút thiêng liêng ấy theo một cách trang trọng, đặc sắc mang dấu ấn riêng. Hạnh
                  phúc thực sự là khi được nắm tay người mình yêu bước vào sảnh cưới, nhận lời chúc
                  phúc của bạn bè, người thân và đọc lời tuyên thệ sẵn sàng chia ngọt sẻ bùi cùng
                  nhau. Sảnh cưới với ánh nên lung linh không chói...
                </div>
                <Link
                  to="/service/wedding"
                  style={{ color: '#8d7535', borderBottom: '1px solid #8d7535' }}
                >
                  <b>Chi tiết</b>
                </Link>
                <div className="img-service">
                  <img src="/img/service_1.png" alt="flower_icon" />
                </div>
              </div>
              <div className="box-service">
                <div className="service-title">NHÀ HÀNG</div>
                <div className="service-content" style={{ textAlign: 'justify' }}>
                  Tinh hoa Ẩm thực Việt. Với phong cách kiến trúc hiện đại cùng cách thức bài trí
                  mang hơi hướng của những nhà hàng đẳng cấp 5 sao. Tọa lạc trên cung đường trung
                  tâm phường Quán Toan, nhà hàng của chúng tôi là sự lựa chọn hấp dẫn cho những sự
                  kiện: tiệc sinh nhật, tiệc đầy tháng, liên hoan các dịp trong năm...
                </div>
                <Link
                  to="/service/restaurant"
                  style={{ color: '#8d7535', borderBottom: '1px solid #8d7535' }}
                >
                  <b>Chi tiết</b>
                </Link>
                <div className="img-service">
                  <img src="/img/service_3.png" alt="flower_icon" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="d-flex flex-column">
              <div className="box-service">
                <div className="service-title">PHÒNG VIP</div>
                <div className="service-content" style={{ textAlign: 'justify' }}>
                  Rượu vang là đặc trưng của các phòng ăn VIP tại Phú Mỹ Hưng. Các phòng ăn là khu
                  riêng biệt với nhà hàng. Để đảm bảo sự riêng tư tuyệt đối cho khách hàng. Khi sử
                  dụng dịch vụ tại đây thực khách như lạc bước vào không gian các huyền bí mang đậm
                  phong cách châu Âu. Nội thất hoàn toàn...
                </div>
                <Link
                  to="/service/vip"
                  style={{ color: '#8d7535', borderBottom: '1px solid #8d7535' }}
                >
                  <b>Chi tiết</b>
                </Link>
                <div className="img-service">
                  <img className="scale-100" src="/img/service_2.png" alt="flower_icon" />
                </div>
              </div>
              <div className="box-service">
                <div className="service-title">CAFE</div>
                <div className="service-content" style={{ textAlign: 'justify' }}>
                  Một sớm mai thức dậy nhâm nhi một tách cà phê, thật trọn vẹn cho cả một ngày làm
                  việc hiệu quả. Không gian sân vườn với cây xanh, cỏ cây hoa lá, đài phun nước và
                  hồ cá coi quả là một lựa chọn lý tưởng...
                </div>
                <Link
                  to="/service/cafe"
                  style={{ color: '#8d7535', borderBottom: '1px solid #8d7535' }}
                >
                  <b>Chi tiết</b>
                </Link>
                <div className="img-service">
                  <img src="/img/service_4.png" alt="flower_icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCart;
