import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const Reservation = () => {
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [guests, setGuests] = useState('');
  const [service, setService] = useState('');
  const [eventTime, setEventTime] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [note, setNote] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = {
      fullName,
      phoneNumber,
      guests,
      service,
      eventTime,
      eventDate,
      note
    };

    axios
      .post('https://nhahangphumyhung.com/send-email', formData)
      //   .post('http://localhost:5888/send-email', formData)
      .then((response) => {
        console.log('Response from server:', response.data);
        setSubmitSuccess(true);

        // Show success message
        Swal.fire({
          icon: 'success',
          title: 'Đăng ký thành công!',
          showConfirmButton: false,
          timer: 3000 // Auto hide after 3 seconds
        });

        // Hide the form after successful submission for 30 seconds
        setShowSubmitButton(false);
        setTimeout(() => {
          setShowSubmitButton(true);
          setSubmitSuccess(false);
        }, 30000);

        // Reset form fields after successful submission
        setFullName('');
        setPhoneNumber('');
        setGuests('');
        setService('');
        setEventTime('');
        setEventDate('');
        setNote('');
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
      {submitSuccess && (
        <div className="alert alert-success" role="alert">
          Đăng ký thành công!
        </div>
      )}
      <div
        className="my-3 pb-5"
        id="form-register"
        style={{
          paddingTop: '105px',
          backgroundImage: `url('/img/reservation/background-form.png')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
      >
        <div className="row">
          <div className="col-md-2 col-1"></div>
          <div className="col-md-4 col-10 mb-3 d-flex flex-column justify-content-center align-items-center">
            <div
              style={{
                background: 'white',
                padding: '10px',
                borderRadius: '10px',
                boxShadow: '0 2px 4px rgba(0,0,0,.5)',
                maxWidth: '500px',
                width: '90%',
                marginBottom: '10px'
              }}
            >
              <div className="d-flex align-items-center">
                <div>
                  <img
                    src="/img/reservation/icon1.png"
                    alt="flower_icon"
                    style={{ maxWidth: '100%', height: 'auto' }}
                  />
                </div>
                <span
                  style={{
                    color: '#8a722d',
                    fontWeight: 'bold',
                    fontSize: '1.2em',
                    padding: '10px',
                    flex: '1'
                  }}
                >
                  SẢNH TIỆC SANG TRỌNG
                </span>
                <img
                  src="/img/reservation/red.png"
                  alt="flower_icon"
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              </div>
            </div>
            <div
              style={{
                background: 'white',
                padding: '10px',
                borderRadius: '10px',
                boxShadow: '0 2px 4px rgba(0,0,0,.5)',
                maxWidth: '500px',
                width: '90%',
                marginBottom: '10px'
              }}
            >
              <div className="d-flex align-items-center">
                <div>
                  <img
                    src="/img/reservation/icon2.png"
                    alt="flower_icon"
                    style={{ maxWidth: '100%', height: 'auto' }}
                  />
                </div>
                <span
                  style={{
                    color: '#8a722d',
                    fontWeight: 'bold',
                    fontSize: '1.2em',
                    padding: '10px',
                    flex: '1'
                  }}
                >
                  SẮP XẾP ĐA DẠNG
                </span>
                <img
                  src="/img/reservation/red.png"
                  alt="flower_icon"
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              </div>
            </div>
            <div
              style={{
                background: 'white',
                padding: '10px',
                borderRadius: '10px',
                boxShadow: '0 2px 4px rgba(0,0,0,.5)',
                maxWidth: '500px',
                width: '90%',
                marginBottom: '10px'
              }}
            >
              <div className="d-flex align-items-center">
                <div>
                  <img
                    src="/img/reservation/icon3.png"
                    alt="flower_icon"
                    style={{ maxWidth: '100%', height: 'auto' }}
                  />
                </div>
                <span
                  style={{
                    color: '#8a722d',
                    fontWeight: 'bold',
                    fontSize: '1.2em',
                    padding: '10px',
                    flex: '1'
                  }}
                >
                  BÃI ĐẬU XE TIỆN LỢI
                </span>
                <img
                  src="/img/reservation/red.png"
                  alt="flower_icon"
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              </div>
            </div>
            <div
              style={{
                background: 'white',
                padding: '10px',
                borderRadius: '10px',
                boxShadow: '0 2px 4px rgba(0,0,0,.5)',
                maxWidth: '500px',
                width: '90%',
                marginBottom: '10px'
              }}
            >
              <div className="d-flex align-items-center">
                <div>
                  <img
                    src="/img/reservation/icon4.png"
                    alt="flower_icon"
                    style={{ maxWidth: '100%', height: 'auto' }}
                  />
                </div>
                <span
                  style={{
                    color: '#8a722d',
                    fontWeight: 'bold',
                    fontSize: '1.2em',
                    padding: '10px',
                    flex: '1'
                  }}
                >
                  DỊCH VỤ CHUYÊN NGHIỆP
                </span>
                <img
                  src="/img/reservation/red.png"
                  alt="flower_icon"
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              </div>
            </div>
            <div
              style={{
                background: 'white',
                padding: '10px',
                borderRadius: '10px',
                boxShadow: '0 2px 4px rgba(0,0,0,.5)',
                maxWidth: '500px',
                width: '90%',
                marginBottom: '10px'
              }}
            >
              <div className="d-flex align-items-center">
                <div>
                  <img
                    src="/img/reservation/icon5.png"
                    alt="flower_icon"
                    style={{ maxWidth: '100%', height: 'auto' }}
                  />
                </div>
                <span
                  style={{
                    color: '#8a722d',
                    fontWeight: 'bold',
                    fontSize: '1.2em',
                    padding: '10px',
                    flex: '1'
                  }}
                >
                  THIẾT BỊ HIỆN ĐẠI
                </span>
                <img
                  src="/img/reservation/red.png"
                  alt="flower_icon"
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              </div>
            </div>
            <div
              style={{
                background: 'white',
                padding: '10px',
                borderRadius: '10px',
                boxShadow: '0 2px 4px rgba(0,0,0,.5)',
                maxWidth: '500px',
                width: '90%',
                marginBottom: '10px'
              }}
            >
              <div className="d-flex align-items-center">
                <div>
                  <img
                    src="/img/reservation/icon6.png"
                    alt="flower_icon"
                    style={{ maxWidth: '100%', height: 'auto' }}
                  />
                </div>
                <span
                  style={{
                    color: '#8a722d',
                    fontWeight: 'bold',
                    fontSize: '1.2em',
                    padding: '10px',
                    flex: '1'
                  }}
                >
                  THỰC ĐƠN NGON MIỆNG
                </span>
                <img
                  src="/img/reservation/red.png"
                  alt="flower_icon"
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              </div>
            </div>
          </div>

          <div className="col-md-3 col-10 mx-auto mb-3">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src="/img/reservation/icon-leaf-left.png"
                    className="card-img-left img-fluid me-1"
                    alt="Image 1"
                    style={{ maxWidth: '50px' }}
                  />
                  <div className="text-center">
                    <h4 className="card-title mb-0" style={{ display: 'block' }}>
                      ĐĂNG KÝ ĐẶT CHỖ
                    </h4>
                    <img
                      src="/img/reservation/star.png"
                      alt="Additional Image"
                      style={{
                        maxWidth: '100px',
                        marginTop: '10px',
                        display: 'block',
                        margin: '0 auto'
                      }}
                    />
                  </div>
                  <img
                    src="/img/reservation/icon-leaf-right.png"
                    className="card-img-right img-fluid ms-1"
                    alt="Image 2"
                    style={{ maxWidth: '50px' }}
                  />
                </div>

                <form onSubmit={handleFormSubmit}>
                  <div className="row mt-4">
                    <div className="col-lg-6 p-2">
                      <div className="mb-3">
                        <label
                          htmlFor="fullName"
                          className="form-label"
                          style={{ fontWeight: 'bold' }}
                        >
                          Họ & Tên
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="fullName"
                          placeholder="Nhập họ và tên"
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="phoneNumber"
                          className="form-label"
                          style={{ fontWeight: 'bold' }}
                        >
                          Số Điện Thoại
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          id="phoneNumber"
                          placeholder="Nhập số điện thoại"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="guests"
                          className="form-label"
                          style={{ fontWeight: 'bold' }}
                        >
                          Số Lượng Khách
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="guests"
                          placeholder="Nhập số lượng khách"
                          value={guests}
                          onChange={(e) => setGuests(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 p-2">
                      <div className="mb-3">
                        <label
                          htmlFor="service"
                          className="form-label"
                          style={{ fontWeight: 'bold' }}
                        >
                          Dịch Vụ
                        </label>
                        <select
                          className="form-select"
                          id="service"
                          value={service}
                          onChange={(e) => setService(e.target.value)}
                        >
                          <option>Vui lòng chọn dịch vụ</option>
                          <option value="service1">Tiệc cưới - Hội nghị</option>
                          <option value="service2">Phòng vip</option>
                          <option value="service3">Nhà hàng</option>
                        </select>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="eventTime"
                          className="form-label"
                          style={{ fontWeight: 'bold' }}
                        >
                          Thời Gian Tổ Chức
                        </label>
                        <select
                          className="form-select"
                          id="eventTime"
                          value={eventTime}
                          onChange={(e) => setEventTime(e.target.value)}
                        >
                          <option>Thời Gian Tổ Chức</option>
                          <option value="morning">Buổi sáng</option>
                          <option value="morning">Buổi trưa</option>
                          <option value="morning">Buổi tối</option>
                        </select>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="eventDate"
                          className="form-label"
                          style={{ fontWeight: 'bold' }}
                        >
                          Ngày Tổ Chức
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="eventDate"
                          value={eventDate}
                          onChange={(e) => setEventDate(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-4">
                        <label htmlFor="note" className="form-label" style={{ fontWeight: 'bold' }}>
                          Ghi Chú
                        </label>
                        <textarea
                          className="form-control"
                          id="note"
                          rows="3"
                          placeholder="Nhập ghi chú"
                          value={note}
                          onChange={(e) => setNote(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={!showSubmitButton}
                    style={{
                      backgroundColor: '#8a722d',
                      border: 'none',
                      display: showSubmitButton ? 'block' : 'none'
                    }}
                    className="btn w-100 mt-3"
                  >
                    ĐĂNG KÝ
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reservation;
