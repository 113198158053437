import React, { useState, useEffect } from 'react';

import ServiceCart from '../components/services';
import InfomationMenu from '../components/infomationMenu';
import Comment from '../components/comment';
import ImageLib from '../components/imageLib';
import ReservationForm from '../components/ReservationForm';
import Services from '../components/services';
import CustomerReviewSlider from '../components/comment';

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div
        style={{ position: 'relative' }}
        className="image-service-wedding-home background-tint pt-5"
      >
        <div className="title-service-wedding">
          <div className="d-flex flex-column align-items-center justify-content-center text-center ">
            <div>
              <span style={{ color: 'white' }}>Trang chủ / Thực đơn</span>
            </div>
            <div className="d-flex flex-row">
              <div>
                <img src="/img/flower_pink_left.png" alt="flower_pink_left" />
              </div>
              <div style={{ color: 'white', textDecoration: 'upercase' }} className="me-1 mx-1">
                THỰC ĐƠN
              </div>
              <div>
                <img src="/img/flower_pink_left.png" alt="flower_pink_left" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ position: 'relative' }}>
        <div
          className="information-section d-flex justify-content-center container"
          style={{
            backgroundColor: 'white',
            padding: '40px 0',
            margin: '0 auto',
            overflow: 'hidden',
            borderRadius: '10px',
            zIndex: '2 !important',
            marginTop: '-50px',
            textAlign: 'center'
          }}
        >
          <p
            style={{
              fontSize: '1rem',
              color: 'black',
              padding: '10px 20px'
            }}
          >
            Tiệc cưới Phú Mỹ Hưng luôn lấy khách hàng làm trung tâm và không ngừng nỗ lực để mang
            đến sự hài lòng cho khách hàng bằng sự chuyên nghiệp, hiệu quả và tận tâm. Được góp phần
            vào sự thành công trong sự kiện của bạn chính là niềm hạnh phúc và vinh dự của chúng
            tôi! Tiệc cưới Phú Mỹ Hưng - nơi giấc mơ thành hiện thực!
          </p>
        </div>
      </div>
      <div className="container-fluid">
        <div class="container-fluid">
          <div className="row mt-5 mb-5 align-items-center">
            <div
              className="nav flex-column nav-pills nav-shadow pb-5 col-12 pe-5 col-md-3"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <button
                data-bs-target="#v-pills-banquet"
                type="button"
                role="tab"
                aria-controls="v-pills-banquet"
                aria-selected="true"
                id="v-pills-banquet-tab"
                data-bs-toggle="pill"
                className="d-flex flex-row d-flex justify-content-start align-items-center mt-1 mb-1 nav-link active btn-image-lib"
              >
                <div>
                  <img src="/img/flower_icon.png" alt="flower_icon" />
                </div>
                <div
                  className="image-lib-title  mx-2"
                  style={{
                    textTransform: 'uppercase'
                  }}
                >
                  THỰC ĐƠN CAO CẤP
                </div>
              </button>

              <div
                data-bs-target="#v-pills-confurent"
                type="button"
                role="tab"
                aria-controls="v-pills-confurent"
                aria-selected="true"
                id="v-pills-confurent-tab"
                data-bs-toggle="pill"
                className="d-flex flex-row d-flex justify-content-start align-items-center mt-1 mb-1 nav-link btn-image-lib"
              >
                <div>
                  <img src="/img/flower_icon.png" alt="flower_icon" />
                </div>
                <div
                  className="image-lib-title  mx-2"
                  style={{
                    textTransform: 'uppercase'
                  }}
                >
                  THỰC ĐƠN TIÊU CHUẨN
                </div>
              </div>
              <div
                data-bs-target="#v-pills-vip"
                type="button"
                role="tab"
                aria-controls="v-pills-vip"
                aria-selected="true"
                id="v-pills-vip-tab"
                data-bs-toggle="pill"
                className="d-flex flex-row d-flex justify-content-start align-items-center mt-1 mb-1 nav-link btn-image-lib"
              >
                <div>
                  <img src="/img/flower_icon.png" alt="flower_icon" />
                </div>
                <div
                  className="image-lib-title mx-2"
                  style={{
                    textTransform: 'uppercase'
                  }}
                >
                  THỰC ĐƠN NHÀ HÀNG
                </div>
              </div>
              <div
                data-bs-target="#v-pills-restaurent"
                type="button"
                role="tab"
                aria-controls="v-pills-restaurent"
                aria-selected="true"
                id="v-pills-restaurent-tab"
                data-bs-toggle="pill"
                className="d-flex flex-row d-flex justify-content-start align-items-center mt-1 mb-1 nav-link btn-image-lib"
              >
                <div>
                  <img src="/img/flower_icon.png" alt="flower_icon" />
                </div>
                <div
                  className="image-lib-title  mx-2"
                  style={{
                    textTransform: 'uppercase'
                  }}
                >
                  ĐỒ UỐNG
                </div>
              </div>
            </div>
            <div className="tab-content col-12 col-md-9" id="v-pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="v-pills-banquet"
                role="tabpanel"
                aria-labelledby="v-pills-banquet-tab"
              >
                <div id="demo" class="carousel slide" data-bs-ride="carousel">
                  <style>
                    {`
              @media (min-width: 768px) {
                .img-responsive {
                  width: 50%; 
                }
              }
              @media (max-width: 767px) {
                .img-responsive {
                  width: 100%; 
                }
              }
            `}
                  </style>
                  <div class="carousel-indicators">
                    <button
                      type="button"
                      data-bs-target="#demo"
                      data-bs-slide-to="0"
                      class="active"
                    ></button>
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
                  </div>
                  <div class="carousel-inner">
                    <div class="carousel-item active d-flex justify-content-center">
                      <img
                        src="/img/menu/menu1.png"
                        alt="Los Angeles"
                        class="d-block img-responsive"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-confurent"
                role="tabpanel"
                aria-labelledby="v-pills-confurent-tab"
              >
                <div id="demo" class="carousel slide" data-bs-ride="carousel">
                  <style>
                    {`
              @media (min-width: 768px) {
                .img-responsive {
                  width: 50%; 
                }
              }
              @media (max-width: 767px) {
                .img-responsive {
                  width: 100%; 
                }
              }
            `}
                  </style>
                  <div class="carousel-indicators">
                    <button
                      type="button"
                      data-bs-target="#demo"
                      data-bs-slide-to="0"
                      class="active"
                    ></button>
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
                    <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
                  </div>

                  <div class="carousel-inner">
                    <div class="carousel-item active d-flex justify-content-center">
                      <img
                        src="/img/menu/menu2.png"
                        alt="Los Angeles"
                        class="d-block img-responsive"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
