import ServiceCart from '../components/services';
import ImageLib from '../components/imageLib';
import React, { useState, useEffect } from 'react';
import ReservationForm from '../components/ReservationForm';

import CustomerReviewSlider from '../components/comment';

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="image-home background-tint">
        <div className="title-contact title-home-page">
          <div
            className="d-flex flex-column align-items-center justify-content-center text-center"
            style={{ color: 'white' }}
          >
            <div style={{ fontSize: '70px' }}>PHÚ MỸ HƯNG</div>
            <div style={{ fontSize: '40px' }}>
              <i>Trung tâm tổ chức tiệc cưới & Sự kiện</i>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="d-flex flex-row justify-content-center align-items-center mt-5 mb-5">
          <div>
            <img src="/img/flower_icon.png" alt="flower_icon" />
          </div>
          <div
            className="upercase title-hone-intro-common"
            style={{ textTransform: 'uppercase', margin: '0 10px 0 10px', fontSize: '32px' }}
          >
            Giới thiệu chung
          </div>

          <div>
            <img src="/img/flower_icon.png" alt="flower_icon" />
          </div>
        </div>
        <div>
          Trung tâm Tổ chức Sự kiện và Tiệc cưới Phú Mỹ Hưng được thành lập tháng 11 năm 2023 cung
          cấp cho khách hàng 04 dịch vụ chính, bao gồm: Dịch vụ Tiệc cưới, Dịch vụ Hội Nghị - Hội
          Thảo, Dịch vụ Tiệc sinh nhật, Cà phê. Cơ sở tọa lạc tại các vị trí đắc địa Trung tâm khu
          vực Quán Toan trực thuộc phường Quán Toan, quận Hồng Bàng, thành phố Hải Phòng, phong cách
          mà chúng tôi hướng đến và mong muốn đem đến cho khách hàng :” Trẻ Trung - Thanh Lịch -
          Hiện Đại”, Tiệc cưới Phú Mỹ Hưng tự hào là điểm đến hấp dẫn bậc nhất trong lĩnh vực tổ
          chức sự kiện và tiệc cưới. Tiệc cưới Phú Mỹ Hưng luôn lấy khách hàng làm trung tâm và
          không ngừng nỗ lực để mang đến sự hài lòng cho khách hàng bằng sự chuyên nghiệp, hiệu quả
          và tận tâm. Được góp phần vào sự thành công trong sự kiện của bạn chính là niềm hạnh phúc
          và vinh dự của chúng tôi! Tiệc cưới Phú Mỹ Hưng -nơi giấc mơ thành hiện thực!
        </div>
      </div>
      <ServiceCart />
      <div style={{ marginTop: '150px' }}>
        <ImageLib />
      </div>
      <div>
        <ReservationForm />
      </div>
      <div>
        <CustomerReviewSlider />
      </div>
    </div>
  );
};

export default HomePage;
