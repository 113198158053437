import ServiceCart from '../components/services';
import ImageLib from '../components/imageLib';
import React, { useState, useEffect } from 'react';
const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div style={{ position: 'relative' }} className="image-contact-home pt-5">
        <div className="title-contact">
          <div className="d-flex flex-column align-items-center justify-content-center text-center ">
            <div>
              <img src="/img/logo.png" alt="" />
            </div>
            <div style={{ fontSize: '48px' }}>LIÊN HỆ</div>
            <div>
              <span style={{ fontWeight: '700' }}>Trang chủ ></span> Liên hệ
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div
          className="d-flex flex-column justify-content-center  text-center "
          style={{ marginTop: '100px' }}
        >
          <div style={{ fontSize: '36px', fontWeight: 'bold' }}>Hãy liên hệ với chúng tôi</div>
          <div style={{ padding: '0 30%', color: '#9F9F9F' }}>
            Để biết thêm thông tin về các dịch vụ của chúng tôi. Xin vui lòng gửi email cho chúng
            tôi. Nhân viên của chúng tôi luôn ở đó để giúp bạn. Đừng ngần ngại!
          </div>
        </div>

        <div className="row " style={{ marginTop: '150px' }}>
          <div className="col-md-6 col-12">
            <div className="d-flex flex-column ">
              <div className="d-flex flex-row ">
                <div>
                  <i style={{ fontSize: '40px' }} class="fa fa-map-marker" aria-hidden="true"></i>
                </div>
                <div>
                  <div className="d-flex flex-column">
                    <div>
                      <span style={{ marginLeft: '15px', fontSize: '24px' }}>Địa chỉ</span>
                    </div>
                    <div>
                      <span style={{ marginLeft: '15px' }}>
                        số 215 Hải Triều, Quán Toan, Hồng Bàng, Hải Phòng{' '}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row mt-5">
                <div>
                  <i style={{ fontSize: '40px' }} class="fa fa-phone" aria-hidden="true"></i>
                </div>
                <div>
                  <div className="d-flex flex-column">
                    <div>
                      <span style={{ marginLeft: '15px', fontSize: '24px' }}>Số điện thoại</span>
                    </div>
                    <div style={{ marginLeft: '15px' }}>
                      <div>Mobile: 0981316565</div>
                      <div>Hotline: 0981316565</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row mt-5">
                <div>
                  <i style={{ fontSize: '40px' }} class="fa fa-clock-o" aria-hidden="true"></i>
                </div>
                <div>
                  <div className="d-flex flex-column">
                    <div>
                      <span style={{ marginLeft: '15px', fontSize: '24px' }}>Giờ mở cửa</span>
                    </div>
                    <div style={{ marginLeft: '15px' }}>
                      <div>Thứ 2 - Thứ 6: 8:00 - 22:00</div>
                      <div>Thứ 7 - Chủ nhật: 9:00 - 21:00</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 mb-5">
            <form>
              <div class="mb-3">
                <label for="lbName" class="form-label">
                  Tên của bạn
                </label>
                <input type="text" class="form-control" id="lbName" />
              </div>
              <div class="mb-3">
                <label for="lbEmail" class="form-label">
                  Email
                </label>
                <input type="email" class="form-control" id="lbEmail" />
              </div>
              <div class="mb-3">
                <label for="lbSubject" class="form-label">
                  Chủ đề
                </label>
                <input type="text" class="form-control" id="lbSubject" />
              </div>
              <div class="mb-3">
                <label for="lbContent" class="form-label">
                  Nội dung
                </label>
                <textarea col="10" class="form-control" id="lbContent" />
              </div>

              <button style={{ background: '#B88E2F' }} type="submit" class="btn ">
                <span style={{ color: 'white', padding: '10px 20px' }}>Gửi thư</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
