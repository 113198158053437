import ServiceCart from '../components/services';
import ReservationForm from '../components/ReservationForm';
import ImageLib from '../components/imageLib';
import React, { useState, useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import CustomerReviewSlider from '../components/comment';
import InformationSection from '../components/infomationMenu';
import { imageCaffe, imageStores, imageVips, imageWedding } from '../data/slider';

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div
        style={{ position: 'relative' }}
        className="image-service-wedding-home background-tint pt-5"
      >
        <div className="title-service-wedding">
          <div className="d-flex flex-column align-items-center justify-content-center text-center ">
            <div>
              <span style={{ color: 'white' }}>Trang chủ / Dịch vụ / Tổ chức tiệc cưới</span>
            </div>
            <div className="d-flex flex-row">
              <div>
                <img src="/img/flower_pink_left.png" alt="flower_pink_left" />
              </div>
              <div style={{ color: 'white', textDecoration: 'upercase' }} className="me-1 mx-1">
                TIỆC CƯỚI - HỘI NGHỊ
              </div>
              <div>
                <img src="/img/flower_pink_left.png" alt="flower_pink_left" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ position: 'relative' }}>
        <InformationSection />
      </div>
      <div className="container">
        <div
          style={{ marginTop: '100px' }}
          className="d-flex flex-row justify-content-center align-items-center mb-5"
        >
          <div>
            <img src="/img/flower_icon.png" alt="flower_icon" />
          </div>
          <div
            className="upercase title-hone-intro-common"
            style={{ textTransform: 'uppercase', margin: '0 10px 0 10px', fontSize: '32px' }}
          >
            KỊCH BẢN TIỆC CƯỚI
          </div>

          <div>
            <img src="/img/flower_icon.png" alt="flower_icon" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="circle-wedding col-md-4 col-12">
            <div className="background-service-wedding-1 background-tint">
              <div className="title-img-wedding">Đón khách</div>
            </div>
          </div>
          <div className="circle-wedding col-md-4 col-12">
            <div className="background-service-wedding-2 background-tint">
              <div className="title-img-wedding">Chương trình tiệc cưới</div>
            </div>
          </div>
          <div className="circle-wedding col-md-4 col-12">
            <div className="background-service-wedding-3 background-tint">
              <div className="title-img-wedding">Phục vụ</div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <Link to="/script">
            <button
              className="btn btn-outline-secondary"
              style={{ fontWeight: '700', padding: '5px 15px' }}
            >
              CHI TIẾT
            </button>
          </Link>
        </div>
      </div>
      <div className="container-fluid">
        <div
          style={{ marginTop: '100px' }}
          className="d-flex flex-row justify-content-center align-items-center  "
        >
          <div>
            <img src="/img/flower_left.png" alt="flower_icon" />
          </div>
          <div
            className="upercase title-hone-intro-common"
            style={{ textTransform: 'uppercase', margin: '0 10px 0 10px', fontSize: '32px' }}
          >
            Dịch vụ phục vụ
          </div>

          <div>
            <img style={{ float: 'right' }} src="/img/flower_right.png" alt="flower_icon" />
          </div>
        </div>

        <div class="container">
          <div className="row mt-5 mb-5 align-items-center">
            <div
              className="nav flex-column nav-pills nav-shadow pb-5 col-12 pe-5 col-md-3"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <button
                data-bs-target="#v-pills-banquet"
                type="button"
                role="tab"
                aria-controls="v-pills-banquet"
                aria-selected="true"
                id="v-pills-banquet-tab"
                data-bs-toggle="pill"
                className="d-flex flex-row d-flex justify-content-start align-items-center mt-1 mb-1 nav-link active btn-image-lib"
              >
                <div>
                  <img src="/img/flower_icon.png" alt="flower_icon" />
                </div>
                <div
                  className="image-lib-title mx-2"
                  style={{
                    textTransform: 'uppercase'
                  }}
                >
                  Tiệc cưới
                </div>
              </button>

              <div
                data-bs-target="#v-pills-confurent"
                type="button"
                role="tab"
                aria-controls="v-pills-confurent"
                aria-selected="true"
                id="v-pills-confurent-tab"
                data-bs-toggle="pill"
                className="d-flex flex-row d-flex justify-content-start align-items-center mt-1 mb-1 nav-link btn-image-lib"
              >
                <div>
                  <img src="/img/flower_icon.png" alt="flower_icon" />
                </div>
                <div
                  className="image-lib-title  mx-2"
                  style={{
                    textTransform: 'uppercase'
                  }}
                >
                  Hội nghị - hội thảo
                </div>
              </div>
              <div
                data-bs-target="#v-pills-vip"
                type="button"
                role="tab"
                aria-controls="v-pills-vip"
                aria-selected="true"
                id="v-pills-vip-tab"
                data-bs-toggle="pill"
                className="d-flex flex-row d-flex justify-content-start align-items-center mt-1 mb-1 nav-link btn-image-lib"
              >
                <div>
                  <img src="/img/flower_icon.png" alt="flower_icon" />
                </div>
                <div
                  className="image-lib-title mx-2"
                  style={{
                    textTransform: 'uppercase'
                  }}
                >
                  Tiệc sinh nhật
                </div>
              </div>
              <div
                data-bs-target="#v-pills-restaurent"
                type="button"
                role="tab"
                aria-controls="v-pills-restaurent"
                aria-selected="true"
                id="v-pills-restaurent-tab"
                data-bs-toggle="pill"
                className="d-flex flex-row d-flex justify-content-start align-items-center mt-1 mb-1 nav-link btn-image-lib"
              >
                <div>
                  <img src="/img/flower_icon.png" alt="flower_icon" />
                </div>
                <div
                  className="image-lib-title  mx-2"
                  style={{
                    textTransform: 'uppercase'
                  }}
                >
                  Tri ân khách hàng
                </div>
              </div>
              <button
                data-bs-target="#v-pills-caffe"
                type="button"
                role="tab"
                aria-controls="v-pills-caffe"
                aria-selected="true"
                id="v-pills-caffe-tab"
                data-bs-toggle="pill"
                className="d-flex flex-row d-flex justify-content-start align-items-center mt-1 mb-1 nav-link btn-image-lib"
              >
                <div>
                  <img src="/img/flower_icon.png" alt="flower_icon" />
                </div>
                <div
                  className="image-lib-title mx-2"
                  style={{
                    textTransform: 'uppercase'
                  }}
                >
                  Ca nhạc - thời trang
                </div>
              </button>
            </div>
            <div className="tab-content col-12 col-md-9" id="v-pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="v-pills-banquet"
                role="tabpanel"
                aria-labelledby="v-pills-banquet-tab"
              >
                <div id="demo" className="carousel slide" data-bs-ride="carousel">
                  <div className="carousel-indicators  pt-4">
                    {imageStores.map((item, index) => {
                      return (
                        <button
                          type="button"
                          data-bs-target="#demo"
                          data-bs-slide-to={index}
                          className={`btn-warning ` + (index == 0 ? 'active' : '')}
                        ></button>
                      );
                    })}
                  </div>

                  <div className="carousel-inner carousel-inner-wedding">
                    {imageStores.map((item, index) => {
                      return (
                        <div className={`carousel-item ` + (index == 0 ? 'active' : '')}>
                          <div>
                            <div>
                              <img
                                src={'/img/slider/wedding/slider_weding_' + item.index + '.png'}
                                alt="Los Angeles"
                                className="image-lib-slider w-100"
                              />
                            </div>
                            <div className="d-flex justify-content-between flex-service-img">
                              <div></div>
                              <div>
                                <div className="d-flex flex-column justify-content-center text-center mt-4">
                                  <div style={{ width: '130px', margin: '0 auto' }}>
                                    PHÚ MỸ HƯNG HẢI PHÒNG
                                  </div>
                                  <div>Giới thiệu về dịch vụ tiệc cưới tại nhà hàng</div>
                                </div>
                              </div>
                              <div className="d-flex align-items-end">
                                <div className="d-flex flex-row align-items-end">
                                  <div className="me-3">
                                    <Link
                                      to="/event"
                                      style={{ borderBottom: '1px solid black', color: 'black' }}
                                    >
                                      Chi tiết
                                    </Link>
                                  </div>
                                  <div style={{ zIndex: 20 }}>
                                    <Link
                                      to="/event"
                                      style={{ borderBottom: '1px solid black', color: 'black' }}
                                    >
                                      <img
                                        style={{ height: '25px' }}
                                        src="/img/image_call_email_location.png"
                                        alt="image_call_email_location"
                                      />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#demo"
                    data-bs-slide="prev"
                  >
                    <span class="carousel-control-prev-icon"></span>
                  </button>
                  <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#demo"
                    data-bs-slide="next"
                  >
                    <span class="carousel-control-next-icon"></span>
                  </button>
                </div>
              </div>
              <div
                className="tab-pane fade pt-4  "
                id="v-pills-confurent"
                role="tabpanel"
                aria-labelledby="v-pills-confurent-tab"
              >
                <div id="demo2" className="carousel slide" data-bs-ride="carousel">
                  <div className="carousel-indicators  pt-4">
                    {imageWedding.map((item, index) => {
                      return (
                        <button
                          type="button"
                          data-bs-target="#demo2"
                          data-bs-slide-to={index}
                          className={`btn-warning ` + (index == 0 ? 'active' : '')}
                        ></button>
                      );
                    })}
                  </div>

                  <div className="carousel-inner carousel-inner-wedding">
                    {imageWedding.map((item, index) => {
                      return (
                        <div className={`carousel-item ` + (index == 0 ? 'active' : '')}>
                          <div>
                            <div>
                              <img
                                src={'/img/slider/store/slider_store_' + item.index + '.png'}
                                alt="Los Angeles"
                                className="image-lib-slider w-100"
                              />
                            </div>
                            <div className="d-flex justify-content-between flex-service-img">
                              <div></div>
                              <div>
                                <div className="d-flex flex-column justify-content-center text-center mt-4">
                                  <div style={{ width: '130px', margin: '0 auto' }}>
                                    PHÚ MỸ HƯNG HẢI PHÒNG
                                  </div>
                                  <div>Giới thiệu về dịch vụ tiệc cưới tại nhà hàng</div>
                                </div>
                              </div>
                              <div className="d-flex align-items-end">
                                <div className="d-flex flex-row align-items-end">
                                  <div className="me-3">
                                    <Link
                                      to="/event"
                                      style={{ borderBottom: '1px solid black', color: 'black' }}
                                    >
                                      Chi tiết
                                    </Link>
                                  </div>
                                  <div style={{ zIndex: 20 }}>
                                    <Link
                                      to="/event"
                                      style={{ borderBottom: '1px solid black', color: 'black' }}
                                    >
                                      <img
                                        style={{ height: '25px' }}
                                        src="/img/image_call_email_location.png"
                                        alt="image_call_email_location"
                                      />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#demo2"
                    data-bs-slide="prev"
                  >
                    <span class="carousel-control-prev-icon"></span>
                  </button>
                  <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#demo2"
                    data-bs-slide="next"
                  >
                    <span class="carousel-control-next-icon"></span>
                  </button>
                </div>
              </div>
              <div
                className="tab-pane fade pt-4  "
                id="v-pills-vip"
                role="tabpanel"
                aria-labelledby="v-pills-vip-tab"
              >
                <div id="demo3" className="carousel slide" data-bs-ride="carousel">
                  <div className="carousel-indicators  pt-4">
                    {imageWedding.map((item, index) => {
                      return (
                        <button
                          type="button"
                          data-bs-target="#demo3"
                          data-bs-slide-to={index}
                          className={`btn-warning ` + (index == 0 ? 'active' : '')}
                        ></button>
                      );
                    })}
                  </div>

                  <div className="carousel-inner carousel-inner-wedding">
                    {imageWedding.map((item, index) => {
                      return (
                        <div className={`carousel-item ` + (index == 0 ? 'active' : '')}>
                          <div>
                            <div>
                              <img
                                src={'/img/slider/romVip/slider_vip_' + item.index + '.png'}
                                alt="Los Angeles"
                                className="image-lib-slider w-100"
                              />
                            </div>
                            <div className="d-flex justify-content-between flex-service-img">
                              <div></div>
                              <div>
                                <div className="d-flex flex-column justify-content-center text-center mt-4">
                                  <div style={{ width: '130px', margin: '0 auto' }}>
                                    PHÚ MỸ HƯNG HẢI PHÒNG
                                  </div>
                                  <div>Giới thiệu về dịch vụ tiệc cưới tại nhà hàng</div>
                                </div>
                              </div>
                              <div className="d-flex align-items-end">
                                <div className="d-flex flex-row align-items-end">
                                  <div className="me-3">
                                    <Link
                                      to="/event"
                                      style={{ borderBottom: '1px solid black', color: 'black' }}
                                    >
                                      Chi tiết
                                    </Link>
                                  </div>
                                  <div style={{ zIndex: 20 }}>
                                    <Link
                                      to="/event"
                                      style={{ borderBottom: '1px solid black', color: 'black' }}
                                    >
                                      <img
                                        style={{ height: '25px' }}
                                        src="/img/image_call_email_location.png"
                                        alt="image_call_email_location"
                                      />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#demo3"
                    data-bs-slide="prev"
                  >
                    <span class="carousel-control-prev-icon"></span>
                  </button>
                  <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#demo3"
                    data-bs-slide="next"
                  >
                    <span class="carousel-control-next-icon"></span>
                  </button>
                </div>
              </div>
              <div
                className="tab-pane fade pt-4  "
                id="v-pills-restaurent"
                role="tabpanel"
                aria-labelledby="v-pills-restaurent-tab"
              >
                <div id="demo4" className="carousel slide" data-bs-ride="carousel">
                  <div className="carousel-indicators  pt-4">
                    {imageWedding.map((item, index) => {
                      return (
                        <button
                          type="button"
                          data-bs-target="#demo4"
                          data-bs-slide-to={index}
                          className={`btn-warning ` + (index == 0 ? 'active' : '')}
                        ></button>
                      );
                    })}
                  </div>

                  <div className="carousel-inner carousel-inner-wedding">
                    {imageWedding.map((item, index) => {
                      return (
                        <div className={`carousel-item ` + (index == 0 ? 'active' : '')}>
                          <div>
                            <div>
                              <img
                                src={'/img/slider/romVip/slider_vip_' + item.index + '.png'}
                                alt="Los Angeles"
                                className="image-lib-slider w-100"
                              />
                            </div>
                            <div className="d-flex justify-content-between flex-service-img">
                              <div></div>
                              <div>
                                <div className="d-flex flex-column justify-content-center text-center mt-4">
                                  <div style={{ width: '130px', margin: '0 auto' }}>
                                    PHÚ MỸ HƯNG HẢI PHÒNG
                                  </div>
                                  <div>Giới thiệu về dịch vụ tiệc cưới tại nhà hàng</div>
                                </div>
                              </div>
                              <div className="d-flex align-items-end">
                                <div className="d-flex flex-row align-items-end">
                                  <div className="me-3">
                                    <Link
                                      to="/event"
                                      style={{ borderBottom: '1px solid black', color: 'black' }}
                                    >
                                      Chi tiết
                                    </Link>
                                  </div>
                                  <div style={{ zIndex: 20 }}>
                                    <Link
                                      to="/event"
                                      style={{ borderBottom: '1px solid black', color: 'black' }}
                                    >
                                      <img
                                        style={{ height: '25px' }}
                                        src="/img/image_call_email_location.png"
                                        alt="image_call_email_location"
                                      />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#demo4"
                    data-bs-slide="prev"
                  >
                    <span class="carousel-control-prev-icon"></span>
                  </button>
                  <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#demo4"
                    data-bs-slide="next"
                  >
                    <span class="carousel-control-next-icon"></span>
                  </button>
                </div>
              </div>
              <div
                className="tab-pane fade pt-4 "
                id="v-pills-caffe"
                role="tabpanel"
                aria-labelledby="v-pills-caffe-tab"
              >
                <div id="demo5" className="carousel slide" data-bs-ride="carousel">
                  <div className="carousel-indicators pt-5">
                    {imageWedding.map((item, index) => {
                      return (
                        <button
                          type="button"
                          data-bs-target="#demo5"
                          data-bs-slide-to={index}
                          className={`btn-warning ` + (index == 0 ? 'active' : '')}
                        ></button>
                      );
                    })}
                  </div>

                  <div className="carousel-inner carousel-inner-wedding">
                    {imageWedding.map((item, index) => {
                      return (
                        <div className={`carousel-item ` + (index == 0 ? 'active' : '')}>
                          <div>
                            <div>
                              <img
                                src={'/img/slider/wedding/slider_weding_' + item.index + '.png'}
                                alt="Los Angeles"
                                className="image-lib-slider w-100"
                              />
                            </div>
                            <div className="d-flex justify-content-between flex-service-img">
                              <div></div>
                              <div>
                                <div className="d-flex flex-column justify-content-center text-center mt-4">
                                  <div style={{ width: '130px', margin: '0 auto' }}>
                                    PHÚ MỸ HƯNG HẢI PHÒNG
                                  </div>
                                  <div>Giới thiệu về dịch vụ tiệc cưới tại nhà hàng</div>
                                </div>
                              </div>
                              <div className="d-flex align-items-end">
                                <div className="d-flex flex-row align-items-end">
                                  <div className="me-3">
                                    <Link
                                      to="/event"
                                      style={{ borderBottom: '1px solid black', color: 'black' }}
                                    >
                                      Chi tiết
                                    </Link>
                                  </div>
                                  <div style={{ zIndex: 20 }}>
                                    <Link
                                      to="/event"
                                      style={{ borderBottom: '1px solid black', color: 'black' }}
                                    >
                                      <img
                                        style={{ height: '25px' }}
                                        src="/img/image_call_email_location.png"
                                        alt="image_call_email_location"
                                      />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#demo5"
                    data-bs-slide="prev"
                  >
                    <span class="carousel-control-prev-icon"></span>
                  </button>
                  <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#demo5"
                    data-bs-slide="next"
                  >
                    <span class="carousel-control-next-icon"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid ">
          <div style={{ marginTop: '100px' }} class="row mb-5">
            <div className="col-md-2 col-12"></div>
            <div className="col-md-8 col-12">
              <div className="d-flex flex-row justify-content-center question-area">
                <div
                  className="d-flex flex-column p-3 question-area-item"
                  style={{ width: '650px' }}
                >
                  <div className="mb-5 img-question-normal">
                    <img src="/img/question_normal.png" alt="question_normal" />
                  </div>
                  <div className="title-question" style={{ textTransform: 'uppercase' }}>
                    Trung tâm có sẵn chỗ đậu xe cho khách mời không?
                  </div>
                  <div className="title-answer">
                    Trung tâm có khu vực sân bãi để xe có sức chứa từ 200-300 xe ôtô và 500-600 xe
                    máy. Trung tâm giữ xe hoàn toàn miễn phí.
                  </div>
                  <div className="title-question" style={{ textTransform: 'uppercase' }}>
                    Sức chứa phòng tiệc tối đa và tối thiểu là bao nhiêu?
                  </div>
                  <div className="title-answer">
                    Sảnh lớn của trung tâm có sức chứa tối đa 700 khách, tối thiểu 100 khách, ngoài
                    ra chúng tôi còn cung cấp các phòng tiệc nhỏ với sức chứa từ 10 – 50 khách.
                  </div>
                  <div className="title-question" style={{ textTransform: 'uppercase' }}>
                    Thời gian phục vụ của trung tâm như thế nào?
                  </div>
                  <div className="title-answer">
                    Thời gian phục vụ của nhà hàng từ 9h đến 22h, thời gian phục vụ của sảnh cưới từ
                    8h đến 21h.
                  </div>
                  <div className="title-question" style={{ textTransform: 'uppercase' }}>
                    Thực đơn và giá cả đặt tiệc như thế nào?
                  </div>
                  <div className="title-answer">
                    Thực đơn đa dạng cho thực khách lựa chọn. Giá thành theo thực đơn. Tiêu trí của
                    Phú Mỹ Hưng mong muốn đáp ứng mọi nhu cầu về giá cả và hình thức của khách hàng.
                  </div>
                </div>

                <div
                  className="d-flex flex-column p-3 question-area-item"
                  style={{ width: '400px' }}
                >
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <div>
                      <img className="me-2" src="/img/question_icon_1.png" alt="question_icon_1" />
                    </div>
                    <div style={{ color: '#8D7535', textTransform: 'uppercase' }}>
                      SẢNH TIỆC SANG TRỌNG
                    </div>
                    <div className="mx-2">
                      <img src="/img/flower_icon.png" alt="flower_icon" />
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center mt-3 justify-content-between">
                    <div>
                      <img className="me-2" src="/img/question_icon_2.png" alt="question_icon_1" />
                    </div>
                    <div style={{ color: '#8D7535', textTransform: 'uppercase' }}>
                      SẮP XẾP ĐA DẠNG 
                    </div>
                    <div className="mx-2">
                      <img src="/img/flower_icon.png" alt="flower_icon" />
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center mt-3 justify-content-between">
                    <div>
                      <img className="me-2" src="/img/question_icon_3.png" alt="question_icon_1" />
                    </div>
                    <div style={{ color: '#8D7535', textTransform: 'uppercase' }}>
                      BÃI ĐẬU XE TIỆN LỢI
                    </div>
                    <div className="mx-2">
                      <img src="/img/flower_icon.png" alt="flower_icon" />
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center mt-3 justify-content-between">
                    <div>
                      <img className="me-2" src="/img/question_icon_4.png" alt="question_icon_1" />
                    </div>
                    <div style={{ color: '#8D7535', textTransform: 'uppercase' }}>
                      DỊCH VỤ CHUYÊN NGHIỆP
                    </div>
                    <div className="mx-2">
                      <img src="/img/flower_icon.png" alt="flower_icon" />
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center mt-3 justify-content-between">
                    <div>
                      <img className="me-2" src="/img/question_icon_5.png" alt="question_icon_1" />
                    </div>
                    <div style={{ color: '#8D7535', textTransform: 'uppercase' }}>
                      THIẾT BỊ HIỆN ĐẠI
                    </div>
                    <div className="mx-2">
                      <img src="/img/flower_icon.png" alt="flower_icon" />
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center mt-3 justify-content-between">
                    <div>
                      <img className="me-2" src="/img/question_icon_6.png" alt="question_icon_1" />
                    </div>
                    <div style={{ color: '#8D7535', textTransform: 'uppercase' }}>
                      THỰC ĐƠN NGON MIỆNG
                    </div>
                    <div className="mx-2">
                      <img src="/img/flower_icon.png" alt="flower_icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-12"></div>
          </div>
        </div>
      </div>
      <div>
        <ReservationForm />
      </div>

      <div>
        <CustomerReviewSlider />
      </div>
    </div>
  );
};

export default Contact;
