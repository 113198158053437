import { useState } from 'react';
import emailjs from 'emailjs-com';
import React from 'react';
import { Outlet, Link } from 'react-router-dom';

const initialState = {
  name: '',
  email: '',
  message: ''
};
const Footer = () => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);

    {
      /* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */
    }

    emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_PUBLIC_KEY').then(
      (result) => {
        console.log(result.text);
        clearState();
      },
      (error) => {
        console.log(error.text);
      }
    );
  };
  return (
    <div>
      <div className="container-fluid footer-register-gift">
        <div className="row ">
          <div className="col-12 col-md-6 ">
            <div style={{ height: '100% ' }} className="row align-items-center">
              <div
                style={{
                  color: '#9F9F9F',
                  fontWeight: '700',
                  textDecoration: 'upercase'
                }}
                className="col-12 col-md-3"
              >
                ĐĂNG KÝ NHẬN ƯU ĐÃI
              </div>
              <div className="col-12 col-md-6 d-flex flex-row justify-content-start">
                <div>
                  <input placeholder="Email của bạn" className="intput-register" type="email" />
                </div>
                <div>
                  <button className="btn-register">Đăng ký</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="contact-img-footer d-flex flex-row justify-content-end align-items-center">
              <div className="me-2">
                <Link to={'https://www.facebook.com/phumyhung215haitrieuquantoan?mibextid=ZbWKwL'}>
                  <img src="/img/facebook.png" alt="facebook" />
                </Link>
              </div>
              <div className="me-2">
                <Link to={'tel:0981316565'}>
                  <img src="/img/zalo.png" alt="facebook" />
                </Link>
              </div>
              <div className="me-5">
                <img src="/img/intagram.png" alt="facebook" />
              </div>
              <div>Kết nối với chúng tôi</div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="mt-5 mb-5 container-fluid">
        <div className="row">
          <div className="col-12 col-lg-4 col-md-4">
            <div className="d-flex flex-column justify-content-center">
              <div>
                <img src="/img/logo-big.png" alt="logo-big" />
              </div>
              <div
                style={{
                  color: '#9F9F9F',
                  fontWeight: '700',
                  width: '200px',

                  textAlign: 'center'
                }}
              >
                0981316565 - số 215, Hải Triều, Quán Toan, Hồng Bàng, Hải Phòng
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 col-md-4">
            <div className="d-flex flex-row">
              <div>
                <div
                  style={{
                    color: '#9F9F9F',
                    fontWeight: '700',
                    textDecoration: 'upercase',
                    marginRight: '30px'
                  }}
                >
                  CHỨC NĂNG
                </div>
                <div className="mt-5">
                  <Link to="/"> Trang chủ</Link>
                </div>
                <div className="mt-2">
                  <Link to="/menu"> Thực đơn</Link>
                </div>
                <div className="mt-2">
                  <Link to="/service"> Dịch vụ</Link>
                </div>
                <div className="mt-2">
                  <Link to="/service"> Ưu đãi</Link>
                </div>
                <div className="mt-2">
                  <Link to="/contact">Liên hệ</Link>
                </div>
              </div>
              <div>
                <div
                  style={{
                    color: '#9F9F9F',
                    fontWeight: '700',
                    textDecoration: 'upercase',
                    marginRight: '30px'
                  }}
                >
                  CHĂM SÓC KHÁCH HÀNG
                </div>
                <div className="mt-5">
                  <Link to="/contact"> Hướng dẫn thanh toán</Link>
                </div>
                <div className="mt-2">
                  <Link to="/policy">Chính sách ưu đãi</Link>
                </div>
                <div className="mt-2">
                  <Link to="/policy">Chính sách chăm sóc khách hàng</Link>
                </div>
                <div className="mt-2">
                  <Link to="/policy">Chính sách chung</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 col-md-4"></div>
        </div>
      </div>
      <hr />
      <div className="pb-5"></div>
    </div>
  );
};

export default Footer;
