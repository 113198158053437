import ServiceCart from '../components/services';
import InfomationMenu from '../components/infomationMenu';
import Comment from '../components/comment';
import ImageLib from '../components/imageLib';
import ReservationForm from '../components/ReservationForm';
import Services from '../components/services';
import CustomerReviewSlider from '../components/comment';
import React, { useState, useEffect } from 'react';

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div
        style={{ position: 'relative' }}
        className="image-service-wedding-home background-tint pt-5"
      >
        <div className="title-service-wedding">
          <div className="d-flex flex-column align-items-center justify-content-center text-center ">
            <div>
              <span style={{ color: 'white' }}>Trang chủ / Dịch vụ</span>
            </div>
            <div className="d-flex flex-row">
              <div>
                <img src="/img/flower_pink_left.png" alt="flower_pink_left" />
              </div>
              <div style={{ color: 'white', textDecoration: 'upercase' }} className="me-1 mx-1">
                DỊCH VỤ
              </div>
              <div>
                <img src="/img/flower_pink_left.png" alt="flower_pink_left" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ position: 'relative' }}>
        <div
          className="information-section d-flex justify-content-center container"
          style={{
            backgroundColor: 'white',
            padding: '40px 0',
            margin: '0 auto',
            overflow: 'hidden',
            borderRadius: '10px',
            zIndex: '2 !important',
            marginTop: '-50px',
            textAlign: 'center'
          }}
        >
          <p
            style={{
              fontSize: '1rem',
              color: 'black',
              padding: '10px 20px'
            }}
          >
            Tiệc cưới Phú Mỹ Hưng luôn lấy khách hàng làm trung tâm và không ngừng nỗ lực để mang
            đến sự hài lòng cho khách hàng bằng sự chuyên nghiệp, hiệu quả và tận tâm. Được góp phần
            vào sự thành công trong sự kiện của bạn chính là niềm hạnh phúc và vinh dự của chúng
            tôi! Tiệc cưới Phú Mỹ Hưng - nơi giấc mơ thành hiện thực!
          </p>
        </div>
      </div>

      <div>
        <Services />
      </div>
      <div style={{ marginTop: '100px' }}>
        <ReservationForm />
      </div>
      <div>
        <Comment />
      </div>
    </div>
  );
};

export default Contact;
