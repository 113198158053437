import ServiceCart from '../components/services';
import ReservationForm from '../components/ReservationForm';
import Comment from '../components/comment';
import ImageLib from '../components/imageLib';
import React, { useState, useEffect } from 'react';
import CustomerReviewSlider from '../components/comment';

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div
        style={{ position: 'relative' }}
        className="image-service-wedding-home background-tint pt-5"
      >
        <div className="title-service-wedding">
          <div className="d-flex flex-column align-items-center justify-content-center text-center ">
            <div>
              <span style={{ color: 'white' }}>
                Trang chủ / Kịch bản cưới hoàn hảo cho Cô dâu - Chú rể tại tiệc cưới Phú Mỹ Hưng
              </span>
            </div>
            <div className="d-flex flex-row">
              <div>
                <img src="/img/flower_pink_left.png" alt="flower_pink_left" />
              </div>
              <div style={{ color: 'white', textDecoration: 'upercase' }} className="me-1 mx-1">
                Kịch bản cưới hoàn hảo cho Cô dâu - Chú rể tại tiệc cưới Phú Mỹ Hưng
              </div>
              <div>
                <img src="/img/flower_pink_left.png" alt="flower_pink_left" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container bg-white p-3">
        <p className="mt-4">
          Để có một đám cưới thành công và hoàn hảo, việc xây dựng kế hoạch chi tiết là điều hết sức
          quan trọng. Hiểu được điều này, tiệc cưới Phú Mỹ Hưng đã nghiên cứu và xây dựng nên kịch
          bản cưới mẫu giúp cô dâu - chú rể có thêm ý tưởng cho đám cưới của mình.
        </p>
        <h5 className="mb-3 font-weight-bold">CHƯƠNG TRÌNH TIỆC CƯỚI</h5>
        <ul className="list-unstyled" style={{ listStyleType: 'disc' }}>
          <li style={{ marginLeft: '20px' }}>
            Trong lúc đón khách, màn chiếu 2 bên sân khấu hoặc màn hình LED sẽ trình chiếu những
            clip hoặc ảnh của cô dâu, chú rể (nếu có)
          </li>
          <li style={{ marginLeft: '20px' }}>
            Ánh sáng trong phòng tiệc rực rỡ, ca sĩ trình bày những bài hát về tình yêu MC mời khách
            ổn định chỗ ngồi (nếu có)
          </li>
          <li style={{ marginLeft: '20px' }}>
            MC dẫn dắt câu chuyện về mối lương duyên của đôi uyên ương (nếu có)
          </li>
          <li style={{ marginLeft: '20px' }}>
            MC giới thiệu bố mẹ cô dâu chú rể và mời bố mẹ 2 bên lên sân khấu
          </li>
          <li style={{ marginLeft: '20px' }}>MC giới thiệu cô dâu chú rể (Ánh sáng mờ ảo)</li>
          <li style={{ marginLeft: '20px' }}>
            Các thiên thần đi vào phòng cưới vừa đi vừa rắc hoa (nếu có)
          </li>
          <li style={{ marginLeft: '20px' }}>
            Cô dâu chú rể tiến vào phòng cưới (nhạc nền, đèn chiếu rọi theo bước chân cô dâu chú rể,
            đá khói tạo không khí vừa lãng mạn vừa trang trọng)
          </li>
          <li style={{ marginLeft: '20px' }}>Cô dâu chú rể lên sân khấu</li>
          <li style={{ marginLeft: '20px' }}>
            MC giới thiệu đại diện hai họ lên phát biểu (nếu có)
          </li>
          <li style={{ marginLeft: '20px' }}>Bố mẹ trao quà (nếu có)</li>
          <li style={{ marginLeft: '20px' }}>
            Chú rể trao nhẫn cho cô dâu và cùng nhau đọc lời thề ước (nếu có)
          </li>
          <li style={{ marginLeft: '20px' }}>Cô dâu chú rể cắt bánh cưới</li>
          <li style={{ marginLeft: '20px' }}>
            Cô dâu chú rể rót Champagne (đá khói bay lên từ tháp Champagne)
          </li>
          <li style={{ marginLeft: '20px' }}>
            Cô dâu, chú rể nói lời cảm ơn bố mẹ, cảm ơn khách mời tới dự (nếu có)
          </li>
          <li style={{ marginLeft: '20px' }}>
            MC mời cô dâu chú rể và khách mời nâng cốc chúc mừng hạnh phúc
          </li>
          <li style={{ marginLeft: '20px' }}>Nhập tiệc</li>
          <li style={{ marginLeft: '20px' }}>
            Chương trình ca nhạc do các ca sĩ trình bày (nếu có)
          </li>
          <li style={{ marginLeft: '20px' }}>
            Cô dâu chú rể cùng bố mẹ đi chào khách. Cùng lúc cô dâu chú rể và khách mời nâng cốc,
            nhân viên phục vụ mang đồ ăn nóng tiến vào phòng tiệc
          </li>
        </ul>

        <h5 className="mb-3 font-weight-bold">PHỤC VỤ</h5>
        <ul className="list-unstyled" style={{ listStyleType: 'disc' }}>
          <li style={{ marginLeft: '20px' }}>Đồ ăn tiếp tục được phục vụ</li>
          <li style={{ marginLeft: '20px' }}>
            Cô dâu chú rể cùng bố mẹ cảm ơn và tiễn khách tại cổng ra vào
          </li>
        </ul>

        <h5 className="mb-3 font-weight-bold">KẾT THÚC PHỤC VỤ</h5>
        <p>
          Lưu ý: trước giờ tiệc khoảng 20 phút, MC dẫn chương trình sẽ gặp trực tiếp gia chủ để trao
          đổi các thông tin về chương trình và kịch bản tiệc cưới. Đây là kịch bản cưới chi tiết
          hoàn hảo cho các cặp đôi khi tổ chức đám cưới ngoài các trung tâm, khách sạn. Hy vọng nó
          sẽ là gợi ý hữu ích với bạn!
        </p>
      </div>
      <div>
        <ReservationForm />
      </div>
      <div>
        <Comment />
      </div>
    </div>
  );
};

export default Contact;
