import React, { useState, useEffect } from 'react';
import { Navigation } from './components/navigation';
import Header from './components/header';

import HomePage from './pages/home';
import Contact from './pages/contact';
import ServiceWedding from './pages/serviceWedding';
import Script from './pages/script';
import Event from './pages/event';
import Service from './pages/service';
import Menu from './pages/menu';
import Restaurant from './pages/restaurant';
import Cafe from './pages/cafe';
import Vip from './pages/viproom';
import ReservationForm from './components/ReservationForm';
import Policy from './pages/policy';

import Footer from './components/footer';
import JsonData from './data/data.json';
import SmoothScroll from 'smooth-scroll';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Header data={landingPageData.Header} />

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/reservation" element={<ReservationForm />} />
          <Route path="/service/wedding" element={<ServiceWedding />} />
          <Route path="/policy" element={<Policy />} />

          <Route path="/script" element={<Script />} />
          <Route path="/event" element={<Event />} />
          <Route path="/service" element={<Service />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/service/restaurant" element={<Restaurant />} />
          <Route path="/service/cafe" element={<Cafe />} />
          <Route path="/service/vip" element={<Vip />} />
        </Routes>

        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
