import React, { useState } from 'react';

const InformationSection = () => {
  const [activeInfo, setActiveInfo] = useState('price'); // Thiết lập giá trị mặc định cho activeInfo

  const infoContent = {
    price: {
      header:
        'Rượu vang là đặc trưng của các phòng ăn VIP tại Phú Mỹ Hưng. Các phòng ăn là khu riêng biệt với nhà hàng. Để đảm bảo sự riêng tư tuyệt đối cho khách hàng. Khi sử dụng dịch vụ tạiđây thực khách như lạc bước vào không gian các huyền bí mang đậm phong cách châu Âu. Nội thất hoàn toàn sử dùng các sản phẩm cao cấp bố trí ngăn nắp, đảm bảo cả hình thức lẫn kết cấu.',
      content: (
        <div>
          <span>
            Để được tư vấn thêm về giá dịch vụ tại tiệc cưới Phú Mỹ Hưng, quý khách vui lòng liên
            hệ:
          </span>
          <p style={{ margin: 0 }}>Hotline: 0981.316.565</p>
          <p style={{ margin: 0 }}>Email:</p>
          <a href="/contact" style={{ display: 'inline-block' }}>
            Liên hệ
            <span style={{ marginLeft: '5px' }}>➔ phumyhung215haitrieuquantoan@gmail.com</span>
          </a>
        </div>
      )
    },

    menu: {
      header:
        'Rượu vang là đặc trưng của các phòng ăn VIP tại Phú Mỹ Hưng. Các phòng ăn là khu riêng biệt với nhà hàng. Để đảm bảo sự riêng tư tuyệt đối cho khách hàng. Khi sử dụng dịch vụ tạiđây thực khách như lạc bước vào không gian các huyền bí mang đậm phong cách châu Âu. Nội thất hoàn toàn sử dùng các sản phẩm cao cấp bố trí ngăn nắp, đảm bảo cả hình thức lẫn kết cấu.',
      content: (
        <div>
          <p>
            Được thực hiện từ bàn tay những người đầu bếp tài hoa từng làm việc tại các nhà hàng 5
            sao, thực đơn của Tiệc cưới Phú Mỹ Hưng vô cùng ngon miệng và nhiều món ăn tinh tế. Với
            giá thành rẻ, thực đơn của Tiệc cưới Phú Mỹ Hưng vẫn đáp ứng đầy đủ các yêu cầu của
            những vị khách khó tính nhất.
          </p>
          <a href="/menu" className="btn btn-outline-dark">
            Xem thêm
          </a>
        </div>
      )
    },
    offers: {
      header:
        'Một sớm mai thức dậynhâm nhi một tách cà phê, thật trọn vẹn cho cả một ngày làm việc hiệu quả.Không gian sân vườn với cây xanh, cỏ cây hoa lá, đài phun nước và hồ cá coi quảlà một lựa chọn lý tưởng.',
      content:
        'Tại Tiệc cưới Phú Mỹ Hưng, chúng tôi luôn tạo điều kiện thuận lợi nhất cho khách hàng bằng các chương trình ưu đãi dành cho tiệc cưới, tiệc sự kiện, tiệc sinh nhật đến các sự kiện quan trọng như hội nghị, hội thảo, …Chúng tôi đề xuất những gói sản phẩm và dịch vụ chất lượng, giúp tiết kiệm ngân sách nhưng vẫn đáp ứng mọi tiêu chí của bạn.'
    },
    reservation: {
      header:
        'Rượu vang là đặc trưng của các phòng ăn VIP tại Phú Mỹ Hưng. Các phòng ăn là khu riêng biệt với nhà hàng. Để đảm bảo sự riêng tư tuyệt đối cho khách hàng. Khi sử dụng dịch vụ tạiđây thực khách như lạc bước vào không gian các huyền bí mang đậm phong cách châu Âu. Nội thất hoàn toàn sử dùng các sản phẩm cao cấp bố trí ngăn nắp, đảm bảo cả hình thức lẫn kết cấu.',
      content:
        'Tại Tiệc cưới Phú Mỹ Hưng, chúng tôi luôn tạo điều kiện thuận lợi nhất cho khách hàng bằng các chương trình ưu đãi dành cho tiệc cưới, tiệc sự kiện, tiệc sinh nhật đến các sự kiện quan trọng như hội nghị, hội thảo, …Chúng tôi đề xuất những gói sản phẩm và dịch vụ chất lượng, giúp tiết kiệm ngân sách nhưng vẫn đáp ứng mọi tiêu chí của bạn'
    }
  };

  const handleButtonClick = (key) => {
    setActiveInfo(key);
    if (key === 'reservation') {
      const reservationSection = document.getElementById('form-register');
      reservationSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div
      className="information-section d-flex justify-content-center container"
      style={{
        backgroundColor: '#f8f9fa',
        padding: '40px 0',
        margin: '0 auto',
        overflow: 'hidden',
        borderRadius: '10px',
        zIndex: '2 !important', // Thiết lập z-index
        marginTop: '-50px' // Thiết lập margin âm
      }}
    >
      <div className="text-center" style={{ maxWidth: '58.333333%' }}>
        <p style={{ fontSize: '1rem', color: 'black', marginBottom: '20px' }}>
          {infoContent[activeInfo].header} {/* Hiển thị header của mục đã chọn */}
        </p>
        <div
          className="btn-group mb-4 d-flex flex-row justify-content-center"
          role="group"
          style={{ margin: '10px -10px' }}
        >
          <button
            type="button"
            className="btn"
            onClick={() => handleButtonClick('price')}
            style={{
              margin: '0 10px',
              backgroundColor: activeInfo === 'price' ? '#8a722d' : '#fff',
              borderColor: '#8a722d',
              color: activeInfo === 'price' ? '#fff' : '#8a722d'
            }}
          >
            Bảng giá
          </button>
          <button
            type="button"
            className="btn"
            onClick={() => handleButtonClick('menu')}
            style={{
              margin: '0 10px',
              backgroundColor: activeInfo === 'menu' ? '#8a722d' : '#fff',
              borderColor: '#8a722d',
              color: activeInfo === 'menu' ? '#fff' : '#8a722d'
            }}
          >
            Thực đơn
          </button>
          <button
            type="button"
            className="btn"
            onClick={() => handleButtonClick('offers')}
            style={{
              margin: '0 10px',
              backgroundColor: activeInfo === 'offers' ? '#8a722d' : '#fff',
              borderColor: '#8a722d',
              color: activeInfo === 'offers' ? '#fff' : '#8a722d'
            }}
          >
            Ưu đãi
          </button>
          <button
            type="button"
            className="btn"
            onClick={() => handleButtonClick('reservation')}
            style={{
              margin: '0 10px',
              backgroundColor: activeInfo === 'reservation' ? '#8a722d' : '#fff',
              borderColor: '#8a722d',
              color: activeInfo === 'reservation' ? '#fff' : '#8a722d'
            }}
          >
            Đặt chỗ
          </button>
        </div>
        <div
          className="info-content"
          style={{
            backgroundColor: '#fff',
            borderRadius: '5px',
            padding: '20px',
            marginTop: '20px'
          }}
        >
          <p>{infoContent[activeInfo].content}</p> {/* Hiển thị nội dung của mục đã chọn */}
        </div>
      </div>
    </div>
  );
};

export default InformationSection;
