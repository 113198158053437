import ServiceCart from '../components/services';
import ReservationForm from '../components/ReservationForm';
import Comment from '../components/comment';
import ImageLib from '../components/imageLib';
import React, { useState, useEffect } from 'react';
import CustomerReviewSlider from '../components/comment';

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div
        style={{ position: 'relative' }}
        className="image-service-wedding-home background-tint pt-5"
      >
        <div className="title-service-wedding">
          <div className="d-flex flex-column align-items-center justify-content-center text-center ">
            <div>
              <span style={{ color: 'white' }}>Trang chủ / chính sách chung</span>
            </div>
            <div className="d-flex flex-row">
              <div>
                <img src="/img/flower_pink_left.png" alt="flower_pink_left" />
              </div>
              <div style={{ color: 'white', textDecoration: 'upercase' }} className="me-1 mx-1">
                Chính sách chung
              </div>
              <div>
                <img src="/img/flower_pink_left.png" alt="flower_pink_left" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container bg-white p-3">
        <h5 className="mb-3 font-weight-bold mt-4">
          Tại Phú Mỹ Hưng, chúng tôi thực hiện đồng bộ hóa các chính sách
        </h5>
        <p className="mt-4">
          <div>1. Chính sách ưu đãi hấp dẫn </div>
          Với những cặp đôi còn đắn đo về nhiều khoản tiền phải bỏ ra, quyết định chọn Phú Mỹ Hưng
          là điều sáng suốt. Chúng tôi thường xuyên đưa ra các gói khuyến mãi tiệc cưới trong cả
          năm. Gía trị các khuyến mãi thường từ 20-30% tổng giá trị. Ngoài ra trong năm, chúng tôi
          thường có từ 3 đến 4 dịp tung ra các chương trình ưu đãi bàn tiệc và dịch vụ đi kèm. Trong
          thời gian này mức giá cho các bàn tiệc trở nên hấp dẫn bởi chính sách đồng giá tất cả gói
          dịch vụ.
        </p>

        <p className="mt-4">
          <div>2. Chính sách dịch vụ </div>
          Nhiều cặp đôi lo lắng đến chất lượng dịch vụ khi sử dụng gói  khuyến mãi tiệc cưới. Theo
          cam kết của Phú Mỹ Hưng, chúng tôi đảm bảo rằng chất lương tiệc cưới của bạn không đổi khi
          sử dụng các gói khuyến mãi. Bởi chúng tôi hiểu rằng uy tín với khách hàng là điều quan
          trọng nhất. Chất lượng món ăn không đổi, dịch vụ chăm sóc khách hàng không đổi, đội ngũ
          nhân viên phục vụ tận tình, dịch vụ trang trí sảnh cưới độc đáo. Trống Phú Mỹ Hưng cùng
          đội ngũ wedding planner chuyên nghiệp có thể tư vấn giúp bạn những cách siêu tiết kiệm mà
          vẫn có được một bữa tiệc sang trọng đẳng cấp.
        </p>

        <p className="mt-4">
          <div>3. Chính sách thanh toán rõ ràng, tiện lợi </div>Ở Phú Mỹ Hưng, chứng tôi công khai
          các vấn đề đặt cọc và thanh toán đến khách hàng. Khi biết rõ thời gian và số lượng khách
          mời, bạn có một lần đặt cọc để  giữ sảnh. Lần đặt cọc thứ hai sau khi chọn xong thực đơn
          tiệc cưới. Chúng tôi sẽ nhận cọc món ăn của bạn. Cuối cùng là những khoản còn lại sẽ được
          thanh toán sau tiệc cưới. Sau khi giải quyết những băn khoăn của bạn về giá trị những gói
          khuyến mãi tiệc cưới của chúng tôi, chúng tôi hy vọng bạn sẽ có lựa  chọn sáng suốt cho
          ngày vui của mình. Chúc các bạn có những giây phút ngọt ngào để viết tiếp những trang mới
          về câu chuyện tình yêu của hai bạn.
        </p>

        <p className="mt-4">
          <div>Mọi thông tin chi tiết xin vui lòng liên hệ:</div>
          <div>+ Hotline 0964. 257.766</div>
          <div>+ Email: phumyhung215haitrieuquantoan@gmail.com</div>
        </p>
      </div>
      <div>
        <ReservationForm />
      </div>
      <div>
        <Comment />
      </div>
    </div>
  );
};

export default Contact;
