import React from 'react';
import { Outlet, Link } from 'react-router-dom';

const Header = () => {
  return (
    <header id="header" style={{ position: 'fixed', width: '100%', zIndex: '100' }}>
      <nav
        style={{ background: 'rgb(0 0 0 / 75%)' }}
        className="navbar navbar-expand-lg navbar-light "
      >
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <div className="d-flex flex-row align-items-center">
              <div className="p-2 header-logo">
                <img src="/img/logo.png" alt="logo" />
              </div>
              <div
                className="p-2 header-title"
                style={{ fontSize: '34px', fontWeight: '700', color: 'white' }}
              >
                Phú Mỹ Hưng
              </div>
            </div>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div className=" collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto nav-title ">
              <li className="nav-item dropdown">
                <Link
                  className="nav-link mx-2"
                  to="/service"
                  id="dropdownMenuButton"
                  aria-expanded="false"
                >
                  Dịch vụ
                </Link>
                <ul
                  className="dropdown-menu"
                  style={{ background: 'rgba(0, 0, 0, 0.75)' }}
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <Link className="dropdown-item" to="/service/wedding">
                      Tiệc cưới - hội nghị
                    </Link>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/service/vip">
                      Phòng vip
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/service/restaurant">
                      Nhà hàng
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/service/cafe">
                      Cafe
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link mx-2" href="/menu">
                  Thực đơn
                </a>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-2" to="/">
                  Trang chủ
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-2" aria-current="page" to="/service">
                  Ưu đãi
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link mx-2" to="/contact">
                  Liên hệ
                </Link>
              </li>
            </ul>
            <ul className="navbar-nav m0-auto d-none d-lg-inline-flex nav-title">
              <a href="/#form-register">
                <button
                  style={{
                    color: 'white',
                    background: '#B88E2F',
                    border: 'none',
                    borderRadius: '10px'
                  }}
                  className="button-register"
                >
                  <div style={{ padding: '5px' }}>Đặt tiệc</div>
                </button>
              </a>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
