import ServiceCart from '../components/services';
import ReservationForm from '../components/ReservationForm';
import Comment from '../components/comment';
import ImageLib from '../components/imageLib';
import React, { useState, useEffect } from 'react';
import CustomerReviewSlider from '../components/comment';

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div
        style={{ position: 'relative' }}
        className="image-service-wedding-home background-tint pt-5"
      >
        <div className="title-service-wedding">
          <div className="d-flex flex-column align-items-center justify-content-center text-center ">
            <div>
              <span style={{ color: 'white' }}>Trang chủ / Tổ chức sự kiện</span>
            </div>
            <div className="d-flex flex-row">
              <div>
                <img src="/img/flower_pink_left.png" alt="flower_pink_left" />
              </div>
              <div style={{ color: 'white', textDecoration: 'upercase' }} className="me-1 mx-1">
                TỔ CHỨC SỰ KIỆN
              </div>
              <div>
                <img src="/img/flower_pink_left.png" alt="flower_pink_left" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container bg-white p-3">
        <h5 className="mb-3 font-weight-bold">
          Tiệc cưới Phú Mỹ Hưng -Địa điểm tổ chức lý tưởng cho các buổi Hội thảo - Hội nghị
        </h5>
        <p className="mt-4">
          Tổ chức hội nghị, hội thảo là hoạt động thường niên của cácdoanh nghiệp. Đây không chỉ là
          dịp giao lưu, gặp gỡ giữa các đối tác mà còn làhình thức quảng bá sản phẩm giúp chuyển đổi
          khách hàng tiềm năng thành kháchhàng thực sự Hiểu được tầm quan trọng của sự kiện này đối
          với doanhnghiệp, Tiệc cưới Phú Mỹ Hưng đã xây dựng nên một không gian Sang trọng Hiệnđại,
          xứng đáng là địa điểm hoàn hảo cho các buổi Hội thảo - Hội nghị đẳng cấp.
        </p>
        <h5 className="mb-3 font-weight-bold">KHÔNG GIAN HIỆN ĐẠI - SANG TRỌNG</h5>
        <p className="mt-4">
          Trung tâm sở hữu cho mình một phong cách thiết kế tinh tế,sang trọng. Nếu bạn đang có nhu
          cầu tìm cho mình một không gian ấn tượng thìđây sẽ là sự lựa chọn tối ưu. Bên cạnh không
          gian thu hút, hệ thống các phòng sảnh tiệc củaTrung tâm còn được trang bị đầy đủ âm thanh,
          ánh sáng, đèn led, màn hìnhlớn...góp phần truyền tải nội dung, thông điệp của buổi hội
          nghị - hội thảotrọn vẹn đến khách hàng. Sức chứa ở các phòng tiệc cũng vô cùng linh hoạt,
          thế nêndù buổi họp của bạn chỉ có khoảng từ 50 khách hay 1000 khách, Phú Mỹ Hưng đềucó
          không gian phù hợp
        </p>
        <h5 className="mb-3 font-weight-bold">ĐỘI NGŨ NHÂN VIÊN CHUYÊN NGHIỆP, THÂN THIỆN</h5>
        <p className="mt-4">
          “Tốt hơn ngày hôm qua”- Đây là phương châm hoạt động củatrung tâm tiệc cưới Phú Mỹ Hưng .
          Chính bởi vậy, chúng tôi luôn nỗ lực đàotạo nhân viên, hoàn thiện sản phẩm, dịch vụ để
          ngày 1 phát triển đemđến cho khách hàng dịch vụ ngày càng tốt hơn. Dù là một thương
          hiệumới chúng tôi cam kết sẽ đem đến dịch vụ tốt nhất tới khách hàng.Mong muốn của chúng
          tôi là được khách hàng trải nghiệm và tin tưởnglựa chọn.
        </p>
        <h5 className="mb-3 font-weight-bold">THỰC ĐƠN PHONG PHÚ, MÓN ĂN CHẤT LƯỢNG</h5>
        <p className="mt-4">
          Đến với hệ thống trung tâm Sự kiện & Tiệc cưới Phú MỹHưng , quý khách hàng có thể hoàn
          toàn tin tưởng vào chất lượng món ăn. Bởi mỗinguyên liệu đều thực sự tươi ngon, thượng
          hạng, an toàn được tuyển chọn kỹcàng, có nguồn gốc xuất sứ rõ ràng mới đủ tiêu chuẩn chế
          biến mónăn. Đặc biệt, dưới bàn tay những người đầu bếp tài hoatừng làm việc tại các nhà
          hàng nổi tiếng, mỗi món ăn đều trở thành một tácphẩm nghệ thuật chinh phục mọi vị khách.
        </p>
        <h5 className="mb-3 font-weight-bold">VỊ TRÍ THUẬN LỢI</h5>
        <p className="mt-4">
          Bạn muốn tổ chức Hội nghị - Hội thảo ở Quán Toan hay khuvực lân cận. Hãy đến với chúng tôi
          để được tư vấn và trải nghiệmdịch vụ theo phong cách dịch vụ của các nhà hàng đẳng cấp 5
          sao. Để được tư vấn chi tiết thêm về dịch vụ tổ chức Hộinghị - Hội thảo tại Trung tâm Tiệc
          cưới Phú Mỹ Hưng.
        </p>
      </div>
      <div>
        <ReservationForm />
      </div>
      <div>
        <Comment />
      </div>
    </div>
  );
};

export default Contact;
