export const imageStores = [
  {
    index: 1,
    content:
      'Nhà hàng Phú Mỹ Hưng, với thiết kế tinh tế và sang trọng, là địa điểm lý tưởng để thưởng thức ẩm thực đẳng cấp. Không gian rộng lớn của nhà hàng được chia thành nhiều khu vực, từ phòng ăn chính cho đến những góc nhỏ kín đáo, đảm bảo sự riêng tư và thoải mái cho từng nhóm khách hàng.'
  },
  {
    index: 2,
    content:
      'Phú Mỹ Hưng không chỉ là nơi ăn uống, nó còn là địa điểm tổ chức sự kiện với các phòng chức năng lớn có thể điều chỉnh linh hoạt để phù hợp với mọi loại hình sự kiện từ hội nghị kinh doanh đến tiệc cưới. Các phòng được thiết kế thông minh với trang thiết bị âm thanh, ánh sáng hiện đại, đảm bảo một sự kiện hoàn hảo.'
  },
  {
    index: 3,
    content:
      'Dù bạn đang tìm kiếm một bữa tối lãng mạn hay một buổi tiệc gia đình ấm cúng, nhà hàng Phú Mỹ Hưng luôn sẵn sàng phục vụ với một thực đơn phong phú và đội ngũ nhân viên chuyên nghiệp. Sự kết hợp giữa không gian đa dạng và phong cách phục vụ chuyên nghiệp hứa hẹn sẽ đem lại những trải nghiệm ẩm thực không thể quên.'
  },
  {
    index: 4,
    content:
      'Mỗi phòng ăn tại Phú Mỹ Hưng đều có một chủ đề thiết kế riêng biệt, phản ánh sự đa dạng trong văn hóa ẩm thực mà nhà hàng muốn truyền tải. Không chỉ phục vụ các món ăn ngon miệng, nhà hàng còn mang đến trải nghiệm thẩm mỹ tinh tế cho khách hàng thông qua không gian sống động và trang nhã.'
  }
];

export const imageWedding = [
  {
    index: 1,
    content:
      'Nhà hàng Phú Mỹ Hưng tự hào là địa điểm lý tưởng để tổ chức tiệc cưới, với không gian rộng lớn và sức chứa lớn, phù hợp cho những đám cưới hoành tráng và đông vui. Chúng tôi cung cấp một không gian mở, được trang hoàng lộng lẫy, tạo nên một bối cảnh ấn tượng và sang trọng cho ngày trọng đại của các cặp đôi.'
  },
  {
    index: 2,
    content:
      'Tại Phú Mỹ Hưng, chúng tôi không chỉ cung cấp một không gian lý tưởng cho tiệc cưới, mà còn mang đến những gói dịch vụ ưu đãi đặc biệt, bao gồm trang trí, âm thanh ánh sáng, và dịch vụ hỗ trợ nghi lễ, đảm bảo rằng mỗi tiệc cưới không chỉ là một sự kiện, mà còn là một trải nghiệm đáng nhớ cho cô dâu chú rể và quan khách.'
  },
  {
    index: 3,
    content:
      'Sự chuyên nghiệp trong công tác tổ chức tiệc cưới tại Phú Mỹ Hưng được thể hiện qua đội ngũ nhân viên luôn sẵn lòng lắng nghe và thấu hiểu những yêu cầu riêng biệt của từng cặp đôi. Chúng tôi tự hào với dịch vụ cá nhân hóa, luôn nỗ lực để mỗi tiệc cưới không chỉ đạt đến sự hoàn hảo trong từng chi tiết, mà còn phản ánh cá tính và phong cách của hai nhân vật chính.'
  },
  {
    index: 4,
    content:
      'Đội ngũ tổ chức sự kiện của chúng tôi mang đến sự chuyên nghiệp, tỉ mỉ trong từng khâu chuẩn bị, từ lên kế hoạch chi tiết đến thực hiện hoàn hảo trong ngày cưới. Nhà hàng Phú Mỹ Hưng cam kết đem đến dịch vụ đẳng cấp với một đội ngũ nhân viên được đào tạo bài bản, sẵn sàng tận tâm vì một lễ cưới không chỉ trang nhã mà còn trọn vẹn ý nghĩa.'
  }
];

export const imageVips = [
  {
    index: 1,
    content:
      'Phòng VIP của nhà hàng Phú Mỹ Hưng là tác phẩm nghệ thuật của sự sang trọng và tiện nghi, nơi mỗi chi tiết đều được thiết kế tỉ mỉ, mang đậm phong cách châu Âu hiện đại. Nội thất xa hoa với đồ đạc được chọn lọc cẩn thận, tạo nên một không gian đẳng cấp, lý tưởng cho những bữa tiệc riêng tư và những cuộc họp kín đáo.'
  },
  {
    index: 2,
    content:
      'Trong không gian ấm cúng của nhà hàng, các phòng VIP được thiết kế đặc biệt với nội thất cao cấp, tạo ra một không gian độc quyền cho các cuộc gặp gỡ quan trọng hay bữa tiệc sang trọng. Những phòng này không chỉ đảm bảo tính riêng tư mà còn phục vụ nhu cầu của những thực khách khó tính nhất.'
  },
  {
    index: 3,
    content:
      'Trong sự cách biệt của phòng VIP, thực khách có thể tận hưởng sự yên bình và riêng tư tuyệt đối, đi kèm với đó là thực đơn phong phú, phản ánh sự sáng tạo và tinh hoa của ẩm thực châu Âu. Mỗi món ăn được chế biến tỉ mỉ, phục vụ trong không gian lịch sự, đảm bảo một trải nghiệm ẩm thực khó quên.'
  },
  {
    index: 4,
    content:
      'Ở Phú Mỹ Hưng, phòng VIP còn là sự chọn lựa lý tưởng cho những buổi tiệc nhỏ, hội thảo hoặc cuộc gặp gỡ đòi hỏi sự riêng tư cao. Khách hàng có thể yên tâm tổ chức các sự kiện quan trọng của mình tại đây, biết rằng mọi yếu tố từ không gian, ẩm thực, đến dịch vụ đều ở mức xuất sắc.'
  }
];

export const imageCaffe = [
  {
    index: 1,
    content:
      'Không chỉ gây ấn tượng với thiết kế nội thất, cafe Phú Mỹ Hưng còn chú trọng đến không gian ngoại thất với khu vườn xanh mát, những bức tường kính trong suốt nhìn ra khu vườn tạo nên một không gian mở, đầy ánh sáng tự nhiên. Cây cối được bố trí khéo léo xung quanh quán, mang đến cảm giác tươi mới và gần gũi với thiên nhiên.'
  },
  {
    index: 2,
    content:
      'không gian xanh mát của khu vườn, lối đi dẫn vào cafe Phú Mỹ Hưng trải dài, được lát gạch sạch sẽ và bao quanh bởi những hàng cây xanh tươi. Bạn có thể tận hưởng ly cà phê buổi sáng trong lành, hay đơn giản là dành một buổi chiều thư giãn, ngắm nhìn cảnh vật yên bình tại đây.'
  },
  {
    index: 3,
    content:
      'cafe Phú Mỹ Hưng mở ra một không gian trang nhã và thoáng đãng với những chiếc bàn xếp đều đặn và những kệ sách ngăn nắp. Mỗi chi tiết từ ghế ngồi cho đến những tác phẩm trên kệ sách đều được sắp xếp một cách tinh tế, tạo nên một bầu không khí thư thái cho bất cứ ai đang tìm kiếm một góc yên tĩnh để làm việc hoặc đọc sách.'
  },
  {
    index: 4,
    content:
      'Cafe Phú Mỹ Hưng là điểm đến lý tưởng cho những ai tìm kiếm không gian yên bình để thưởng thức cà phê và nhâm nhi bánh mì giòn ngon. Tại đây, bạn có thể cảm nhận được sự kết hợp hoàn hảo giữa nét đẹp của thiết kế hiện đại và sự ấm cúng, thân quen của một quán cafe nhỏ.'
  }
];
