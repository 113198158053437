import React from 'react';
import ServiceCart from '../components/services';
import InfomationMenu from '../components/infomationMenuVip';
import Comment from '../components/comment';
import ImageLib from '../components/imageLib';
import ReservationForm from '../components/ReservationForm';
import Services from '../components/services';
import CustomerReviewSlider from '../components/comment';

const Contact = () => {
  return (
    <div>
      <div
        style={{ position: 'relative' }}
        className="image-service-wedding-home background-tint pt-5"
      >
        <div className="title-service-wedding">
          <div className="d-flex flex-column align-items-center justify-content-center text-center ">
            <div>
              <span style={{ color: 'white' }}>Trang chủ / Dịch vụ / Phòng VIP</span>
            </div>
            <div className="d-flex flex-row">
              <div>
                <img src="/img/flower_pink_left.png" alt="flower_pink_left" />
              </div>
              <div style={{ color: 'white', textDecoration: 'upercase' }} className="me-1 mx-1">
                Phòng VIP
              </div>
              <div>
                <img src="/img/flower_pink_left.png" alt="flower_pink_left" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ position: 'relative' }}>
        <InfomationMenu />
      </div>
      <div style={{ position: 'relative' }}>
        <ImageLib />
      </div>
      <div style={{ marginTop: '100px' }}>
        <ReservationForm />
      </div>
    </div>
  );
};

export default Contact;
